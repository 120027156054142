import { axiosInstance } from "@shared/utils/configs/axiosInstance";
import type { CreateCustomImageRequest, CreateCustomImageResponse } from "./customImage.types";

export function createCustomImage(data: CreateCustomImageRequest) {
  const axiosConfig = {
    headers: {
      Authorization: localStorage.getItem("_accessToken"),
    },
  };
  return axiosInstance.post<CreateCustomImageResponse>("/api/custom/images", data, axiosConfig);
}
