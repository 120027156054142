
import Skeleton from 'react-loading-skeleton';
import styles from './styles-skeleton.module.css';

const ModelCardSkeleton = () => {
  return (
    <div className={styles.model_skeleton}>
      <div className={styles.model_img}></div>

      <div className={styles.model_desc}>
        <Skeleton count={1} style={{marginBottom: "6px", borderRadius: "16px", height: "100%"}}/>
      </div>
    </div>
  )
}

export default ModelCardSkeleton;