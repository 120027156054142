import styles from "./styles_bodytype.module.css";

import Petite from "@assets/constructor/ethnicity/body_petite.png";
import Slim from "@assets/constructor/ethnicity/body_slim.png";
import Athletic from "@assets/constructor/ethnicity/body_athletic.png";
import Voluptuous from "@assets/constructor/ethnicity/body_voluptuous.png";
import Curvy from "@assets/constructor/ethnicity/body_curvy.png";

import BreastFlat from "@assets/constructor/ethnicity/breast_flat.png";
import BreastSmall from "@assets/constructor/ethnicity/breast_small.png";
import BreastMedium from "@assets/constructor/ethnicity/breast_medium.png";
import BreastLarge from "@assets/constructor/ethnicity/breast_large.png";
import BreastHuge from "@assets/constructor/ethnicity/breast_huge.png";

import ButtHuge from "@assets/constructor/ethnicity/butt_small.png";
import ButtMedium from "@assets/constructor/ethnicity/butt_medium.png";
import ButtLarge from "@assets/constructor/ethnicity/butt_large.png";
import ButtSkinny from "@assets/constructor/ethnicity/butt_skinny.png";
import ButtAthletic from "@assets/constructor/ethnicity/butt_athletic.png";
import { IPersonalityKey } from "@shared/interfaces";
import { IUserState, useUserStore } from "@entities/user";
import * as amplitude from "@amplitude/analytics-browser";

interface IProps {
  butt: IPersonalityKey;
  bodyType: IPersonalityKey;
  breastSize: IPersonalityKey;
  validationError: any;
  bodyTypeChoose: (data: IPersonalityKey) => void;
  buttTypeChoose: (data: IPersonalityKey) => void;
  breastSizeChoose: (data: IPersonalityKey) => void;
}
interface IPersonality {
  title: string;
  value: string;
  icon?: string;
}

const BODY_TYPES: IPersonality[] = [
  {
    title: "Petite",
    value: "Petite",
    icon: Petite,
  },
  {
    title: "Slim",
    value: "Slim",
    icon: Slim,
  },
  {
    title: "Athletic",
    value: "Athletic",
    icon: Athletic,
  },
  {
    title: "Voluptuous",
    value: "Voluptuous",
    icon: Voluptuous,
  },
  {
    title: "Curvy",
    value: "Curvy",
    icon: Curvy,
  },
];

const BREAST_SIZES: IPersonality[] = [
  {
    title: "BreastFlat",
    value: "Flat",
    icon: BreastFlat,
  },
  {
    title: "BreastSmall",
    value: "Small",
    icon: BreastSmall,
  },
  {
    title: "BreastMedium",
    value: "Medium",
    icon: BreastMedium,
  },
  {
    title: "BreastLarge",
    value: "Large",
    icon: BreastLarge,
  },
  {
    title: "BreastHuge",
    value: "Huge",
    icon: BreastHuge,
  },
];

const BUTT_TYPES: IPersonality[] = [
  {
    title: "Small",
    value: "Small",
    icon: ButtHuge,
  },
  {
    title: "ButtMedium",
    value: "Medium",
    icon: ButtMedium,
  },
  {
    title: "ButtLarge",
    value: "Large",
    icon: ButtLarge,
  },
  {
    title: "ButtSkinny",
    value: "Skinny",
    icon: ButtSkinny,
  },
  // {
  //   title: "ButtAthletic",
  //   value: "Athletic",
  //   icon: ButtAthletic,
  // },
];

const ChooseBodyType = ({
  butt,
  bodyType,
  breastSize,
  bodyTypeChoose,
  buttTypeChoose,
  breastSizeChoose,
  validationError,
}: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);

  const onBodyTypeChoose = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Body Type Selected`, {
      userType: user.plan,
    });

    bodyTypeChoose(value);
  };

  const onBreastSizeChoose = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Breast Size Selected`, {
      userType: user.plan,
    });

    breastSizeChoose(value);
  };

  const onButtTypeChoose = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Butt Size Selected`, {
      userType: user.plan,
    });

    buttTypeChoose(value);
  };

  return (
    <div>
      <div className={`${validationError?.body_type && styles.createAi_error}`}>
        <h4 className={styles.body_title}>Choose Body Type</h4>
        <div className={styles.body_container}>
          {BODY_TYPES.map((item, index) => (
            <PersonalityItem
              key={index}
              item={item}
              active={bodyType}
              itemChoose={(value) => onBodyTypeChoose(value)}
            />
          ))}
        </div>
      </div>

      <div className={`${validationError?.breast_type && styles.createAi_error}`}>
        <h4 className={styles.body_title}>Choose Breast Size</h4>
        <div className={styles.body_container}>
          {BREAST_SIZES.map((item, index) => (
            <PersonalityItem
              key={index}
              item={item}
              active={breastSize}
              itemChoose={(value) => onBreastSizeChoose(value)}
            />
          ))}
        </div>
      </div>

      <div className={`${validationError?.butt_size && styles.createAi_error}`}>
        <h4 className={styles.body_title}>Choose The Butt Size</h4>
        <div className={styles.body_container}>
          {BUTT_TYPES.map((item, index) => (
            <PersonalityItem key={index} item={item} active={butt} itemChoose={(value) => onButtTypeChoose(value)} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface IPersonalityProps {
  item: IPersonality;
  active: IPersonalityKey;
  itemChoose: (data: IPersonalityKey) => void;
}

const PersonalityItem = ({ item, active, itemChoose }: IPersonalityProps) => {
  return (
    <div
      className={`${styles.body_item} ${active.value === item.value ? styles.body_active : ""}`}
      onClick={() => itemChoose({ value: item.value, icon: item?.icon || "" })}
    >
      <img src={item.icon} alt={item.value} />

      <div className={styles.body_badge}>{item.value}</div>
    </div>
  );
};

export default ChooseBodyType;
