import styles from "./styles.module.css";
import PlanBg from "@assets/plan_bg.png";

const Event = () => {
  return (
    <div className={styles.subscribe_event_text}>
      <img src={PlanBg} alt="plan_bg" />
      <div className={styles.plan_text}>
        <h3>Get An Exclusive</h3>
        <h3>Package Discount </h3>
        <h3>Only Now!</h3>
      </div>
    </div>
  );
};

export default Event;
