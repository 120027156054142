import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { AxiosError } from "axios";
import { AsideStructure } from "@widgets/aside_with_content";
import { Character, CharactersGalleryDialog, GenerateImageResult } from "@widgets/generateImage";
import {
  CharacterPromptArea,
  SuggestedPromptChips,
  SuggestedPromptTab,
  SuggestedPromptTabs,
} from "@features/generateImage";
import { Button } from "@shared/ui/Button";
import { ReactComponent as ArrowRight } from "@shared/assets/arrow_right.svg";
import { ReactComponent as Token } from "@shared/assets/tokenMd.svg";
import { useModelStore } from "@entities/model/model/model.store";
import { ROUTES, type IModel } from "@shared/interfaces";
import { getRandFromArray, JSONParse } from "@shared/libs";
import { createCustomImage, useCustomImageStore } from "@entities/customImage";
import { useUserStore } from "@entities/user";
import { NoTokensPopUp, PremiumPopUp } from "@widgets/plans";
import styles from "./GenerateImagePage.module.css";

const SUGGESTED_PROMPTS = [
  {
    label: "Cute Picture",
    prompt: "Wearing shorts and a crop top shirt, portrait picture, looking at the viewer",
  },
  {
    label: "Walking On The Beach",
    prompt: "Wearing a long sundress, walking on the beach by the sea waves and the palm trees",
  },
  {
    label: "Out For Dinner",
    prompt: "Wearing a pencil dress, sitting at the dinner table in a fancy restaurant at night with dim lights",
  },
];

const ADDITIONAL_SUGGESTED_PROMPTS = {
  action: {
    label: "Action",
    content: [
      { label: "Standing", prompt: "Standing" },
      { label: "Sitting", prompt: "Sitting" },
      { label: "Squatting", prompt: "Squatting" },
      { label: "Lying down", prompt: "Lying down" },
      { label: "Working out", prompt: "Working Out in the gym" },
      { label: "Swimming", prompt: "Swimming in a pool" },
    ],
  },
  body: {
    label: "Body",
    content: [
      { label: "Back", prompt: "Back view" },
      { label: "Front", prompt: "Front View" },
      { label: "Full Body", prompt: "Full Body picture" },
    ],
  },
  clothing: {
    label: "Clothing",
    content: [
      {
        label: "Summer Dress",
        prompt: "wearing a long summer dress in a park with trees and flowers",
      },
      { label: "Bikini", prompt: "wearing a bikini at the beach" },
      { label: "Skirt", prompt: "wearing a skirt and a shirt" },
      { label: "Jeans", prompt: "wearing jeans and a crop top" },
      { label: "Lab Coat", prompt: "wearing a white lab coat and a shirt" },
      {
        label: "Teacher",
        prompt: "wearing a pencil skirt with a blazer and a shirt, teaching in front of the class",
      },
      { label: "Pyjamas", prompt: "wearing comfy pyjamas, sitting on the bed" },
      { label: "Tank Top", prompt: "wearing a tank top and shorts" },
      { label: "Sport", prompt: "wearing a sport outfit, in a gym" },
    ],
  },
  scene: {
    label: "Scene",
    content: [
      { label: "Garden", prompt: "in a flower garden" },
      { label: "Gym", prompt: "in a gym" },
      { label: "Forest", prompt: "in the forest" },
      { label: "Beach", prompt: "at the beach" },
      { label: "Taxi", prompt: "sitting in a taxi" },
    ],
  },
  accessories: {
    label: "Accessories",
    content: [
      { label: "Necklace", prompt: "wearing an elegant necklace" },
      { label: "Glasses", prompt: "wearing stylish glasses" },
      { label: "Candles", prompt: "a room lit with aromatic candles" },
      { label: "Scarf", prompt: "wearing a colorful scarf" },
      { label: "Baseball Cap", prompt: "wearing a baseball cap outdoors" },
    ],
  },
  view: {
    label: "View",
    content: [
      { label: "Close up", prompt: "Close up view" },
      { label: "From above", prompt: "view from above" },
      { label: "From behind", prompt: "view from behind" },
    ],
  },
};

const GENERATION_COST = 2;

type AdditionalSuggestedPrompts = keyof typeof ADDITIONAL_SUGGESTED_PROMPTS;

export function GenerateImagePage() {
  const navigate = useNavigate();
  const isStartedChoosing = window.localStorage.getItem("isStartedChoosing") ?? "";
  const customImageData = JSONParse<{
    user_prompt: string;
    model_id?: number;
  }>(window.localStorage.getItem("customImage") ?? "");
  const { isGenerating, startGeneration, failGeneration } = useCustomImageStore();
  const { user } = useUserStore();
  const { fetchModels, models } = useModelStore();
  const [prompt, setPrompt] = useState(
    customImageData?.user_prompt || getRandFromArray(SUGGESTED_PROMPTS).prompt || ""
  );
  const [character, setCharacter] = useState<IModel | null>(null);
  const [isCharacterPickerOpen, setIsCharacterPickerOpen] = useState(false);
  const [isPremiumPopUpOpen, setIsPremiumPopUpOpen] = useState(true);
  const [isNoTokensPopUpOpen, setIsNoTokensPopUpOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<AdditionalSuggestedPrompts>("action");

  const handleGenerateImage = () => {
    if (!character) {
      return;
    }

    if (!user.id) {
      window.localStorage.setItem(
        "customImage",
        JSON.stringify({
          user_prompt: prompt,
          model_id: character.id,
        })
      );
      navigate(`${ROUTES.REGISTER}?source=generate_image`);
      return;
    }

    if (user.plan !== "premium") {
      setIsPremiumPopUpOpen(true);
      return;
    }

    window.localStorage.removeItem("customImage");
    startGeneration();
    createCustomImage({
      count: 1,
      model_id: character.id as number,
      user_prompt: prompt,
    })
      .then(({ data }) => {
        if (data.current_tokens < GENERATION_COST) {
          setIsNoTokensPopUpOpen(true);
          return;
        }
      })
      .catch((err: AxiosError) => {
        failGeneration(err.message);
      });
  };

  useEffect(() => {
    fetchModels(isStartedChoosing);
  }, [fetchModels, isStartedChoosing]);

  useEffect(() => {
    if (models.length === 0) {
      return;
    }

    if (customImageData?.model_id) {
      const model = models.find((m) => m.id === customImageData.model_id);

      if (model) {
        setCharacter(model);
      }

      return;
    }

    setCharacter(models[0]);
  }, [customImageData?.model_id, models.length]);

  return (
    <>
      <AsideStructure>
        <h1 className={styles.title}>
          <span>Generate</span> Image
        </h1>

        <div className={styles.wrapper}>
          <GenerateImageResult characterName={character?.name} />

          <div className={styles.form}>
            <h4 className={styles.form__title}>Choose Character*</h4>
            <Character
              avatar={character?.photo}
              name={character?.name || "Choose Character"}
              onCharacterChange={() => setIsCharacterPickerOpen(true)}
              className={styles.character}
            />
            <CharacterPromptArea value={prompt} onChange={(e) => setPrompt(e.target.value)} className={styles.prompt} />

            <SuggestedPromptChips
              items={SUGGESTED_PROMPTS}
              onClick={(value) => setPrompt(value)}
              className={styles.prompt__chips}
            />

            <SuggestedPromptTabs className={styles.suggested__prompts}>
              {Object.entries(ADDITIONAL_SUGGESTED_PROMPTS).map(([key, { label }]) => (
                <SuggestedPromptTab
                  key={label}
                  isActive={key === activeTab}
                  onClick={() => setActiveTab(key as AdditionalSuggestedPrompts)}
                >
                  {label}
                </SuggestedPromptTab>
              ))}
            </SuggestedPromptTabs>

            <SuggestedPromptChips
              className={styles.suggested__prompt__chips}
              items={ADDITIONAL_SUGGESTED_PROMPTS[activeTab].content || []}
              onClick={(value) => setPrompt(`${prompt} ${value}`)}
            />

            <div className={styles.actions}>
              <Button
                variant="primary"
                disabled={isGenerating || !character}
                onClick={handleGenerateImage}
                className={styles.generate_button}
                isLoading={isGenerating}
              >
                Generate <ArrowRight />
                {!!user.tokens && user.tokens >= GENERATION_COST && (
                  <span className={styles.generate_button__ribbon}>
                    <Token />
                    <span>{GENERATION_COST} Tokens</span>
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </AsideStructure>

      <CharactersGalleryDialog
        isOpen={isCharacterPickerOpen}
        onClose={() => setIsCharacterPickerOpen(false)}
        onSelected={(model) => setCharacter(model)}
      />

      <PremiumPopUp isOpen={isPremiumPopUpOpen} />

      <NoTokensPopUp isOpen={isNoTokensPopUpOpen} />
    </>
  );
}
