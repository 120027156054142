import ReactDOM from 'react-dom/client';
import App from './App';
import '@shared/ui/reset.css';
import '@shared/ui/app.css';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);

reportWebVitals();
