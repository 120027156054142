import { useState } from "react";
import styles from "./styles.module.css";
import { BonusItem } from "@features/subscription";
import { ButtonFactory } from "@shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import * as amplitude from "@amplitude/analytics-browser";

const BONUSES = [
  {
    id: 1,
    price: 9.99,
    amount: 100,
    bonusesPercentage: 200,
  },
  {
    id: 2,
    price: 34.99,
    amount: 350,
    bonusesPercentage: 210,
  },
  {
    id: 3,
    price: 49.99,
    amount: 550,
    bonusesPercentage: 210,
  },
  {
    id: 4,
    price: 99.99,
    amount: 1150,
    bonusesPercentage: 210,
  },
  {
    id: 5,
    price: 199.99,
    amount: 2400,
    bonusesPercentage: 210,
  },
  {
    id: 6,
    price: 299.99,
    amount: 3750,
    bonusesPercentage: 210,
  },
];

const Bonuses = () => {
  const navigate = useNavigate();
  const [activeBonus, setActiveBonus] = useState<number>(1);

  const payWithCredit = () => {
    const active: any = BONUSES.find((item) => item.id === activeBonus);

    amplitude.track(`Continue Button Clicked for Token Purchase`, {
      tokenAmount: active?.amount,
      price: active?.price,
    });

    navigate(
      `${ROUTES.PAYMENT}?price=${active.price}&amount=${active.amount}&payment_type=buy_tokens`
    );
  };

  const payWithCrypto = () => {
    console.log(
      "payWithCrypto bonus id ->",
      BONUSES.find((item) => item.id === activeBonus)
    );
  };

  const onSetActive = (id: number) => {
    const active = BONUSES.find((item) => item.id === id);

    amplitude.track(`Token Package Selected`, {
      tokenAmount: active?.amount,
      price: active?.price,
    });

    setActiveBonus(id);
  };

  return (
    <div className={styles.subscribe_bonuses}>
      {BONUSES.map((bonusItem) => (
        <BonusItem
          key={bonusItem.id}
          bonus={bonusItem}
          isActive={bonusItem.id === activeBonus}
          setActiveBonus={onSetActive}
        />
      ))}
      <div className={styles.subscribe_actions}>
        <ButtonFactory
          variant="danger"
          label="Pay with Credit / Debit Card"
          type="button"
          onClick={payWithCredit}
        />
        {/* 
        <ButtonFactory
          variant="primary"
          label="Pay with crypto"
          type="button"
          onClick={payWithCrypto}
        /> */}
      </div>
    </div>
  );
};

export default Bonuses;
