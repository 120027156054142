import { AuthBackground } from "@widgets/auth/background";
import { LoginForm } from "@widgets/auth/loginForm";


const LoginPage = () => {
  return (
    <AuthBackground>
      <LoginForm />
    </AuthBackground>
  )
}

export default LoginPage;