import { useState } from "react";
import styles from "./styles.module.css";
import { DropDownMenu } from "@shared/ui/DropDown";

import Info from "@assets/info.svg";
import Arrow from "@assets/arrow_white.svg";
import Picture from "@assets/picture.svg";

export const HEADER_MENU_LIST: any[] = [
  {
    title: "Show Me...",
    value: "show_me",
  },
  {
    title: "Send Me......",
    value: "send_me",
  },
  {
    title: "Send...",
    value: "send",
  },
  {
    title: "Can I see...",
    value: "can_i_see",
  },
  {
    title: "How to use",
    value: "how_to_use",
    icon: Info,
  },
];

interface IProps {
  showHowToUse: () => void;
  onChangeInput: (data: string) => void;
}

const AskMe: React.FC<IProps> = ({ showHowToUse, onChangeInput }: IProps) => {
  const [isDropDownShow, setDropDownShow] = useState(false);

  if (isDropDownShow) {
    document.body.className = "askme_active";
  }

  const onClick = ({ type }: { type: string }) => {
    if (type === "show_me") {
      onChangeInput("Show Me ");
    }
    if (type === "send_me") {
      onChangeInput("Send Me ");
    }

    if (type === "send") {
      onChangeInput("Send ");
    }

    if (type === "can_i_see") {
      onChangeInput("Can I see ");
    }

    if (type === "how_to_use") {
      showHowToUse();
    }

    setDropDownShow(false);
  };

  const children = (
    <div
      onClick={() => setDropDownShow(!isDropDownShow)}
      className={styles.askme_content}
    >
      <div className={styles.askme_content_img}>
        <img src={Picture} alt="user_profile" />
      </div>
      <h5>Ask</h5>
      <div
        className={`${styles.askme_content_arrow} ${
          isDropDownShow && styles.askme_content_arrow_revert
        }`}
      >
        <img src={Arrow} alt="user_profile_arrow" />
      </div>
    </div>
  );

  return (
    <DropDownMenu
      active={""}
      isVisible={isDropDownShow}
      menuList={HEADER_MENU_LIST}
      callBack={onClick}
      onVisibleChange={() => setDropDownShow(!isDropDownShow)}
    >
      {children}
    </DropDownMenu>
  );
};

export default AskMe;
