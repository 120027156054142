
import Skeleton from 'react-loading-skeleton';
import styles from './styles-skeleton.module.css';

const ModelInfoSkeleton = () => {
  return (
    <div className={styles.model_info_skeleton}>
      <div className={styles.model_info_img}>
        <Skeleton width="100%" height="100%"/>
        <div className={styles.model_info_bg}></div>
      </div>
      <div className={styles.model_maininfo}>
        <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "50px"}}/>
      </div>
      <div className={styles.model_maininfo}>
        <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "20px"}}/>
      </div>
      <div className={styles.model_info}>
        <div className={styles.model_personality}>
          <Skeleton circle width={40} height={40}/>
          <div className={styles.model_personality_descr}>
            <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "13px"}}/>
            <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "32px"}}/>
          </div>
        </div>

        <div className={styles.model_personality}>
          <Skeleton circle width={40} height={40}/>
          <div className={styles.model_personality_descr}>
            <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "13px"}}/>
            <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "32px"}}/>
          </div>
        </div>

        <div className={styles.model_personality}>
          <Skeleton circle width={40} height={40}/>
          <div className={styles.model_personality_descr}>
            <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "13px"}}/>
            <Skeleton style={{marginBottom: "6px", borderRadius: "9px", height: "32px"}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelInfoSkeleton;