import styles from "./styles.module.css";
import { ROUTES } from "@shared/interfaces";
import { Link } from "react-router-dom";

const TextSignUp = () => {
  return (
    <div className={styles.text_signup_container}>
      <h5>Don't have an account yet?</h5>
      <Link to={`${ROUTES.REGISTER}?source=Sign In Page`}>Sign up</Link>
    </div>
  );
};

export default TextSignUp;
