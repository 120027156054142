import { clsx } from "clsx";
import { ReactComponent as Plus } from "./assets/plus.svg";
import styles from "./Chip.module.css";

type Props = React.PropsWithChildren<{
  className?: string;
  isPlusSign?: boolean;
  onClick?: () => void;
}>;

const Chip = ({ children, isPlusSign = true, className, onClick }: Props) => {
  return (
    <button type="button" className={clsx(styles.root, className)} onClick={onClick}>
      {isPlusSign && <Plus />}
      <span className={styles.label}>{children}</span>
    </button>
  );
};

export default Chip;
