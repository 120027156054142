import { IUserState, useUserStore } from "@entities/user";
import styles from "./styles.module.css";
import { ROUTES } from "@shared/interfaces";
import { LinkFactory } from "@shared/ui/Link";
import MenuDropDown from "./HeaderMenuDropDown";
import TokenMenuDropDown from "@widgets/header_actions/ui/HeaderTokenMenuDropDown";

const HeaderActions: React.FC = () => {
  const user = useUserStore((state: IUserState) => state.user);

  const renderSubscription = () => {
    if (user.plan === "premium") {
      return <TokenMenuDropDown />;
    }

    if (user.plan === "free" || !user.plan) {
      return (
        <LinkFactory
          label="Subscribe 75% OFF"
          type="danger"
          path={`${ROUTES.PLANS}?source=Discount Button`}
        />
      );
    }
  };

  if (user.id) {
    return (
      <div className={styles.header_action_wrapper}>
        {renderSubscription()}
        <MenuDropDown />
      </div>
    );
  }

  return (
    <div className={styles.header_action_container}>
      <LinkFactory
        label="Register"
        type="danger"
        path={`${ROUTES.REGISTER}?source=Registration Button`}
      />
      <LinkFactory label="Login" path={`${ROUTES.LOGIN}?source=Login Button`} />
    </div>
  );
};

export default HeaderActions;
