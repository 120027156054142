
import styles from "./styles.module.css";
import PlanBg from "@assets/plan_bg.png";

const Event = () => {
  return (
    <div className={styles.subscribe_event_text}>
      <img src={PlanBg} alt="plan_bg"/>
      <div className={styles.plan_text}>
        <h3>
          <span>Get Exclusive </span>
          Discount Only Today!
        </h3>
        <h4>
          Up to <span>75%</span> off for first subscription!
        </h4>
      </div>
    </div>
  );
};

export default Event;
