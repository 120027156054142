import styles from "./styles.module.css";
import { Link, useLocation } from "react-router-dom";
import { ROUTES, ROUTES_QUERY } from "@shared/interfaces";

import LOGO_MAIN from "@assets/logo_main.svg";
import Explore from "@assets/aside/explore.svg";
import ExploreActive from "@assets/aside/explore_active.svg";
import Chat from "@assets/aside/chat.svg";
import ChatActive from "@assets/aside/chat_active.svg";
import Gallery from "@assets/aside/gallery.svg";
import GalleryActive from "@assets/aside/gallery_active.svg";
import Subscription from "@assets/aside/brilliant.svg";
import SubscriptionActive from "@assets/aside/brilliant_active.svg";
import Token from "@assets/tokenMd.svg";
import Settings from "@assets/aside/settings.svg";
import SettingsActive from "@assets/aside/settings_active.svg";
import CreateAi from "@assets/aside/create_ai.svg";
import CreateAiActive from "@assets/aside/create_ai_active.svg";
import GenerateImage from "@assets/aside/generate_image.svg";
import GenerateImageActive from "@assets/aside/generate.svg";
import { IAsideNavItem } from "../";
import { AsideNavItem } from "@shared/ui/Link";
import { IUserState, useUserStore } from "@entities/user";

import Logout from "@assets/aside/logout.svg";
import CloseIcon from "@assets/close.svg";
import MyAi from "@assets/aside/my_ai.svg";
import MyAiActive from "@assets/aside/my_ai_active.svg";
import { useUserAuth } from "@shared/model";

interface IProps {
  showAside: boolean;
  setShowAside: (flag: boolean) => void;
}

const Aside = ({ showAside, setShowAside }: IProps) => {
  const { pathname } = useLocation();
  const user = useUserStore((state: IUserState) => state.user);
  const { logOut } = useUserAuth();

  const isAuth = user.id && user.id !== "initializing";
  var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const isDesktop = width >= 1100;

  let ASIDE_NAV: IAsideNavItem[] = [
    {
      path: `${ROUTES.HOME}?type=${ROUTES_QUERY.female}`,
      title: "Explore",
      icon: Explore,
      activeIcon: ExploreActive,
    },
    {
      path: ROUTES.CHAT,
      title: "Chat",
      icon: Chat,
      activeIcon: ChatActive,
    },
    {
      path: `${ROUTES.GALLERY}?sort_by=latest`,
      title: "Gallery",
      icon: Gallery,
      activeIcon: GalleryActive,
    },
    {
      path: `${ROUTES.CREATE_AI}`,
      title: "Create",
      icon: CreateAi,
      activeIcon: CreateAiActive,
    },
    {
      path: ROUTES.GENERATE_IMAGE,
      title: "Generate",
      icon: GenerateImage,
      activeIcon: GenerateImageActive,
    },
    {
      path: ROUTES.MY_AI,
      title: "My AI",
      icon: MyAi,
      activeIcon: MyAiActive,
    },
    {
      path:
        user.plan === "premium"
          ? `${ROUTES.SUBSCRIBE}?source=Side Menu Buy Tokens`
          : `${ROUTES.PLANS}?source=Side Menu Premium`,
      title: user.plan === "premium" ? "Buy Tokens" : "Premium",
      icon: user.plan === "premium" ? Token : Subscription,
      activeIcon: user.plan === "premium" ? Token : SubscriptionActive,
    },
  ];

  if (isAuth && !isDesktop) {
    ASIDE_NAV.push({
      path: ROUTES.SETTINGS,
      title: "Settings",
      icon: Settings,
      activeIcon: SettingsActive,
      isDivider: true,
    });
  }

  const isPathActive = (path: string): boolean => {
    const getKeyPath = pathname.split("/")[1];
    const isHomePage = getKeyPath === "";

    if (isHomePage && path.includes(ROUTES.HOME)) {
      return true;
    }

    return !isHomePage && path.includes(getKeyPath);
  };

  const onLogoutClick = async () => {
    await logOut();
    localStorage.removeItem("isStartedChoosing");
    localStorage.removeItem("_accessToken");
    localStorage.removeItem("createdAi");
    window.location.replace(`${ROUTES.HOME}`);
  };

  const LogoutButton = (
    <div className={styles.aside_logout} onClick={onLogoutClick}>
      <div className={styles.aside_logout_img}>
        <img src={Logout} alt="user_profile_arrow" />
      </div>
      <h5>Logout</h5>
    </div>
  );

  return (
    <div className={`${styles.aside_container} ${!showAside && styles.show_aside}`}>
      <img alt="close" src={CloseIcon} className={styles.aside_close_icon} onClick={() => setShowAside(false)} />
      <Link to={`${ROUTES.HOME}?type=${ROUTES_QUERY.female}`} className={styles.aside_logo_container}>
        <img src={LOGO_MAIN} alt="main_logo" />
      </Link>
      <aside>
        {ASIDE_NAV.map((nav: IAsideNavItem) => (
          <AsideNavItem item={nav} key={nav.title} isDesktop={isDesktop} isActive={isPathActive(nav.path)} />
        ))}
        {isAuth && LogoutButton}
      </aside>
      <div className={styles.legal}>
        <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
        <Link to={ROUTES.TERMS}>Terms</Link>
      </div>
    </div>
  );
};

export default Aside;
