import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "@shared/assets/close.svg";
import styles from "./Dialog.module.css";

type Props = {
  isOpen: boolean;
  title?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
  closeOnOutsideClick?: boolean;
  actions?: React.ReactNode;
  isContentScrollable?: boolean;
};

export function Dialog({
  isOpen,
  onClose,
  title,
  children,
  actions,
  className,
  isContentScrollable,
  closeOnOutsideClick = true,
}: Props) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const lastActiveElement = useRef<Element | null>(null);
  const firstRender = useRef(true);

  const handleOutsideClick: React.MouseEventHandler = (event) => {
    const dialogNode = dialogRef.current;
    if (closeOnOutsideClick && event.target === dialogNode) {
      onClose?.();
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (isOpen) {
        lastActiveElement.current = document.activeElement;
        dialogRef.current?.showModal();
      } else {
        dialogRef.current?.close();
        (lastActiveElement.current as HTMLElement)?.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const handleCancel: EventListener = (event) => {
      event.preventDefault();
      onClose?.();
    };

    dialogRef.current?.addEventListener("cancel", handleCancel);
    return () => {
      dialogRef.current?.removeEventListener("cancel", handleCancel);
    };
  }, [onClose]);

  return createPortal(
    <dialog
      aria-modal="true"
      aria-labelledby="dialog-title"
      className={clsx(styles.root, className)}
      ref={dialogRef}
      onClick={handleOutsideClick}
    >
      <button type="button" onClick={onClose} className={styles.close}>
        <CloseIcon />
      </button>

      {title && (
        <div className={styles.header}>
          <h2 id="dialog-title" className={styles.title}>
            {title}
          </h2>
        </div>
      )}

      <div className={clsx(styles.content, { [styles["content--scrollable"]]: isContentScrollable })}>{children}</div>

      {actions && <div className={styles.actions}>{actions}</div>}
    </dialog>,
    document.body
  );
}
