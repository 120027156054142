import { useState } from "react";
import styles from "./styles.module.css";
import { ROUTES } from "@shared/interfaces";
import { DropDownMenu } from "@shared/ui/DropDown";
import { IAsideNavItem } from "@widgets/aside";
import HeaderActionContent from "./HeaderActionContent";

import Subscription from "@assets/aside/brilliant.svg";
import SubscriptionActive from "@assets/aside/brilliant_active.svg";
import Settings from "@assets/aside/settings.svg";
import SettingsActive from "@assets/aside/settings.svg";
import Logout from "@assets/aside/logout.svg";
import { useUserAuth } from "@shared/model";

export const HEADER_MENU_LIST: IAsideNavItem[] = [
  {
    path: ROUTES.PLANS,
    title: "Subscription",
    icon: Subscription,
    activeIcon: SubscriptionActive,
    type: "link",
  },
  {
    path: ROUTES.SETTINGS,
    title: "Settings",
    icon: Settings,
    activeIcon: SettingsActive,
    type: "link",
  },
  {
    path: ROUTES.SUBSCRIBE,
    title: "Logout",
    icon: Logout,
    activeIcon: Logout,
    type: "button",
  },
];

const MenuDropDown: React.FC = () => {
  const { logOut } = useUserAuth();
  const [isDropDownShow, setDropDownShow] = useState(false);

  const onVisibleChange = (flag: boolean) => {
    setDropDownShow(flag);
  };

  const callBack = async (event: { type: string }) => {
    await logOut();
    localStorage.removeItem("isStartedChoosing");
    localStorage.removeItem("_accessToken");
    localStorage.removeItem("createdAi");
    setDropDownShow(false);
    window.location.replace(`${ROUTES.HOME}`);
  };

  return (
    <div className={styles.menu_action}>
      <DropDownMenu
        active={""}
        callBack={callBack}
        isVisible={isDropDownShow}
        menuList={HEADER_MENU_LIST}
        onVisibleChange={onVisibleChange}
      >
        <HeaderActionContent isDropDownVisible={isDropDownShow} onClick={onVisibleChange} />
      </DropDownMenu>
    </div>
  );
};

export default MenuDropDown;
