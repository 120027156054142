import { LinkFactory } from "@shared/ui/Link";
import styles from "./styles.module.css";
import Brilliant from "@assets/aside/brilliant_yellow.svg";
import { ROUTES } from "@shared/interfaces";

const GetPremiumBanner = () => {
  return (
    <div className={styles.premium_banner_container}>
      <div className={styles.premium_banner_content}>
        <div className={styles.img_bg}>
          <img src={Brilliant} alt="brilliant" />
        </div>
        <h5 className={styles.premium_banner__text}>
          Subscribe To Generate Images And Unlock Premium Features!
        </h5>
      </div>
      <LinkFactory
        className={[styles.premium_banner__link]}
        label="Get Premium"
        path={
          ROUTES.PLANS +
          "?parent=Premium Tooltip for Image Generation&source=Premium Tooltip for Image Generation"
        }
      />
    </div>
  );
};

export default GetPremiumBanner;
