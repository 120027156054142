import styles from "./styles.module.css";

interface IProps {
  icon: React.ReactElement;
  type?: string;
}

const ModelMessageIcon = ({ icon, type }: IProps) => {
  const classes = [styles.message_wrapper];

  if (type === "model") {
    classes.push(styles.message_model);
  }

  if (type === "user") {
    classes.push(styles.message_self);
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.message_container}>
        <div>{icon}</div>
      </div>
    </div>
  );
};

export default ModelMessageIcon;
