import { IUserState, useUserStore } from "@entities/user";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { Benefits, Bonuses, Event } from "@widgets/plans";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { useEffect, useLayoutEffect, useRef } from "react";
import * as amplitude from "@amplitude/analytics-browser";

const Terms = () => {
  const navigate = useNavigate();
  const isAuth = useUserStore((state: IUserState) => state.user.id);
  const top = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    document.getElementById("root")?.children[0]?.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.subscribe_container}>
      <AsideStructure>
        <div ref={top} />
        <div className={styles.terms_wrapper}>
          <h2 className={styles.title}>Terms</h2>
          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>Introduction</h5>
            <h5 className={styles.par_text}>
              <span>
                These terms govern your use of GetFlirt.AI (the “Website”) and
                related applications or websites (collectively, “Technologies”)
                for personal, non-commercial purposes. By using the Services,
                you agree to these Terms and form a binding agreement with
                InnoAI Development.
              </span>

              <span>
                Our Privacy Policy, though separate, is an essential document
                explaining how we collect and process personal data.
              </span>

              <span>
                GetFlirt.AI is an online platform using artificial intelligence
                to create fictional chat companions (the “AI Characters”).
                Through the Website, you can engage in chat interactions with
                these characters. The Website also generates media, including
                images, videos, and audio (the “Services”). Certain Services may
                require account creation.
              </span>

              <span>
                GetFlirt.AI is operated by InnoAI Development, with company
                number 442239, based at 29 Agiou Pavlou, Nicosia 1107, Cyprus
              </span>
            </h5>
          </p>
          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>
              Article 1. Registration and Access
            </h5>
            <h5 className={styles.par_text}>
              <span>
                Minimum Age: You must be at least 18 years old to use our
                Services.
                <br />
                Registration: Provide accurate and complete information. Do not
                share account credentials. If using on behalf of someone, ensure
                authorization.
              </span>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>Article 2. Using Our Services</h5>
            <h5 className={styles.par_text}>
              <span>
                Allowed Uses: You may use our Services as long as you comply
                with these Terms and all applicable laws.
                <br />
                Registration: Provide accurate and complete information. Do not
                share account credentials. If using on behalf of someone, ensure
                authorization.
              </span>
              <ul className={styles.list}>
                <span className={styles.par_ul}>
                  Prohibited Uses: Do not use the Services for illegal, harmful,
                  or abusive activities, or to misrepresent the output as
                  human-generated. This includes:
                </span>
                <li>Infringing on the rights of others.</li>
                <li>
                  Copying, modifying, selling, or redistributing any of our
                  Services.
                </li>
                <li>
                  Attempting to reverse-engineer, decompile, or extract data
                  from the Services.
                </li>
                <li>Representing generated content as human-created.</li>
                <li>
                  Interfering with Services or bypassing security measures.
                </li>
                <li>Using Services to develop or train competing models.</li>
                <li>
                  Engaging in, promoting, or facilitating any form of
                  exploitation, trafficking, coercion, or violence.
                </li>
                <li>
                  Important: GetFlirt.AI prohibits any activities related to
                  human trafficking, abuse, or exploitation. Violations will
                  result in account termination, potential legal action, and
                  cooperation with authorities if warranted.
                </li>
                <br />
                Third-Party Services: Our Services may include third-party
                software or outputs subject to their own terms. We hold no
                responsibility for Third-Party Output. Feedback: Feedback is
                welcomed and may be used to improve Services without
                compensation.
              </ul>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>Article 3. Content</h5>
            <h5 className={styles.par_text}>
              <span>
                Your Content: You create AI characters with our algorithms. You
                are responsible for any content provided and for compliance with
                laws.
                <br />
                Ownership of Content: All proprietary software, designs, and
                technology belong to GetFlirt.AI or its licensors. Unauthorized
                use may lead to legal action.
                <br />
                Similarity of Content: Given the AI-based nature, similar
                content may be generated for multiple users. Other users’ output
                is not covered by this agreement.
                <br />
                Our Use of Content: We may use your content for training,
                development, and improvement of Services. To opt out of model
                training, contact contact@getflirt.ai; note this may limit
                functionality.
                <br />
                Accuracy Disclaimer: AI outputs are probabilistic and may not
                always be accurate. Do not rely on outputs for critical
                decisions (e.g., credit, employment, housing).
                <br />
                Article 4. Intellectual Property Rights
                <br />
                GetFlirt.AI and its affiliates own all intellectual property
                rights to the Services.
              </span>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>Article 4. Subscription</h5>
            <h5 className={styles.par_text}>
              <span>
                Paid Subscriptions: Some features require a paid subscription,
                detailed before purchase. Subscription management is available
                in account settings.
                <br />
                Fees and Billing: Fees, including subscription charges, are
                clearly outlined before purchase. Ensure accurate billing
                information. Subscriptions auto-renew until canceled.
                <br />
                EEA Consumer Withdrawal: EEA-based consumers may cancel within
                14 days by contacting contact@getflirt.ai.
                <br />
                Cancellation: You can cancel at any time, retaining access until
                the subscription period ends. Refunds are not typically
                provided.
                <br />
                Price Changes: We may alter prices with a 30-day notice.
                <br />
                Termination and Suspension
                <br />
              </span>
            </h5>
          </p>
        </div>
      </AsideStructure>
    </div>
  );
};

export default Terms;
