import styles from "./styles.module.css";
import { Link } from "react-router-dom";

interface IProps {
  label: string;
  path: string;
  className?: string[];
  children?: React.ReactNode;
  onClick?: () => void;
}

const DefaultLink: React.FC<IProps> = ({ label, path, children, onClick, className = [], ...props }: IProps) => {
  const classes = [styles.link_wrapper, ...className];

  if (children) {
    return (
      <Link
        to={path}
        className={classes.join(" ")}
        onClick={(event) => {
          if (onClick) {
            event?.preventDefault();
            onClick();
          }
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link to={path} className={classes.join(" ")}>
      <span>{label}</span>
    </Link>
  );
};

export default DefaultLink;
