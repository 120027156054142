import DefaultLink from "./ui/Default/Default";
import DangerLink from "@shared/ui/Link/ui/Danger/Danger";

interface IProps {
  variant?: "text" | "contained" | "outlined";
  type?: "secondary" | "danger" | "primary";
  label: string;
  path: string;
  className?: string[];
  children?: React.ReactNode;
  onClick?: () => void;
}

const LinkFactory = ({ type, label, path, variant = "contained", onClick, ...props }: IProps) => {
  switch (type) {
    case "primary":
      return <DefaultLink label={label} path={path} {...props} />;
    case "secondary":
      return <DefaultLink label={label} path={path} {...props} />;
    case "danger":
      return <DangerLink label={label} path={path} {...props} />;
    default:
      return <DefaultLink label={label} path={path} {...props} onClick={() => onClick && onClick()} />;
  }
};

export default LinkFactory;
