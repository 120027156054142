import { Dialog } from "@shared/ui/Dialog";
import banner from "@shared/assets/pop_up_banner.webp";
import banner_mobile from "@shared/assets/pop_up_banner__mobile.webp";
import styles from "./LeftBannerPopUp.module.css";

type Props = React.PropsWithChildren<{
  isOpen?: boolean;
}>;

export function LeftBannerPopUp({ isOpen = false, children }: Props) {
  return (
    <Dialog isOpen={isOpen} className={styles.dialog}>
      <div className={styles.root}>
        <div
          className={styles.banner}
          style={{ "--bg": `url(${banner})`, "--bg-mobile": `url(${banner_mobile})` } as React.CSSProperties}
          data-banner-mobile={banner_mobile}
        ></div>
        <div className={styles.content}>{children}</div>
      </div>
    </Dialog>
  );
}
