import styles from "./become-premium.module.css";

import { ReactComponent as Check } from "@shared/assets/check.svg";
import { ROUTES } from "@shared/interfaces";
import UpdatePlanImg from "@assets/update-plan.jpg";
import CloseIcon from "@assets/close_gray.svg";
import PremiumBgSmallResp from "@assets/pop_up_banner__mobile.webp";
import { ButtonFactory } from "@shared/ui/Button";
import { useLocation, useNavigate } from "react-router-dom";

const PREMIUM_FEATURES = [
  "Chat Unlimited With Your AI",
  "Ask Her To Send You The Most Spicy Photos",
  "Generate As Many AI's As You Want",
];

interface IProps {
  onClose?: () => void;
}

const BecomePremium = ({ onClose }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const toPremium = () => {
    localStorage.setItem("returnUrl", pathname);
    navigate(`${ROUTES.PLANS}?source=Become premium to chat popup`);
  };

  return (
    <div className={styles.premium_base}>
      <div className={styles.premium_container}>
        <img src={CloseIcon} className={styles.premium_close_button} alt="close" onClick={onClose} />
        <div className={styles.premium_bg}>
          <img src={PremiumBgSmallResp} alt="buy_tokens" className={styles.premium_bg_img_adaptive} />
          <img src={UpdatePlanImg} alt="buy_tokens" />
        </div>

        <div className={styles.premium_content}>
          <h3 className={styles.title}>
            Become
            <br />
            <span className={styles.title__sub}>Premium to chat</span>
          </h3>
          <ul className={styles.list}>
            {PREMIUM_FEATURES.map((feature) => (
              <li key={feature} className={styles.list__item}>
                <Check className={styles.list__item__icon} />
                {feature}
              </li>
            ))}
          </ul>

          <ButtonFactory label="Become Premium" onClick={toPremium} variant="danger" />
        </div>
      </div>
    </div>
  );
};

export default BecomePremium;
