import { AuthBackground } from "@widgets/auth/background";
import { ForgotPasswordForm } from "@widgets/auth/forgotPassword";

const ForgotPasswordPage = () => {
  return  (
  <AuthBackground>
    <ForgotPasswordForm />
  </AuthBackground>
  )
}

export default ForgotPasswordPage;