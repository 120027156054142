import styles from './styles.module.css';

interface IProps {
  icon: string,
  title: string,
  description: string
}

const ModelInfoPersonalityItem = ({icon, title, description}: IProps) => {
  return (
    <div className={styles.info_personality_container}>
      <div className={styles.info_personality_img}>
        <img src={icon} alt="info_personality"/>
      </div>
      <div className={styles.info_personality_wrapper}>
        <h6 className={styles.info_personality_title}>{title}</h6>
        <h6 className={styles.info_personality_desc}>{description}</h6>
      </div>
    </div>
  )
}

export default ModelInfoPersonalityItem;