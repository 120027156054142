import styles from "./styles.module.css";

interface IProps {
  children: React.ReactElement;
}

const AuthBackground = ({ children }: IProps) => {
  return (
    <div
      className={styles.auth_bg_container}
      data-source="target"
    >
      <div>{children}</div>
    </div>
  );
};

export default AuthBackground;
