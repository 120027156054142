import { clsx } from "clsx";
import styles from "./SuggestedPromptTabs.module.css";

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const SuggestedPromptTabs = ({ children, className }: Props) => {
  return <ul className={clsx(styles.list, className)}>{children}</ul>;
};

export default SuggestedPromptTabs;
