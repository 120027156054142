import styles from './styles.module.css';
import { IInterested } from "@shared/interfaces";

interface IProps {
  item: IInterested;
  onClick: (path:string)=> void,
}

const InterestedItem: React.FC<IProps> = ({ 
  item,
  onClick 
} : IProps) => {
  const { title, icon, background, path } = item;
  return (
    <div style={{  
      backgroundImage: "url(" + background + ")",
      backgroundSize: "contain",
    }}
    className={styles.interested_container}
    onClick={() => onClick(path)}
    >
      <div className={styles.interested_title}>
        <img src={icon} alt={`interested_${title}`}/>
        <h5>{title}</h5>
      </div>
    </div>
  )
}

export default InterestedItem;