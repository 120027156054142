import { ROUTES } from "@shared/interfaces";
import { LinkFactory } from "@shared/ui/Link";
import styles from "./styles.module.css";

import Crown from "@assets/crown.svg";
import { IUserState, useUserStore } from "@entities/user";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const SubscriptionBanner = () => {
  const user = useUserStore((state: IUserState) => state.user);
  const isAuth = useUserStore((state: IUserState) => state.user.id);
  const navigate = useNavigate();
  const promo = timeUntilEndOfDay();
  const [dataPromotion, setDataPromotion] = useState<string>(promo);

  let TIMER;
  useEffect(() => {
    const intervalID = setInterval(() => {
      const promo = timeUntilEndOfDay();
      setDataPromotion(() => promo);
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  function timeUntilEndOfDay(): string {
    const now: Date = new Date();
    const endOfDay: Date = new Date(now);

    // Устанавливаем время конца дня (23:59:59)
    endOfDay.setHours(23, 59, 59, 999);

    // Вычисляем разницу в миллисекундах
    const diff: number = endOfDay.getTime() - now.getTime();

    // Преобразуем разницу в часы, минуты и секунды
    const hours: number = Math.floor(
      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes: number = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((diff % (1000 * 60)) / 1000);

    // Форматируем числа с ведущими нулями
    const formattedHours: string = String(hours).padStart(2, "0");
    const formattedMinutes: string = String(minutes).padStart(2, "0");
    const formattedSeconds: string = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const redirectTo = () => {
    amplitude.track(`Subscription Banner Clicked`, {
      bannerLocation: "Explore Page",
    });

    if (!isAuth) {
      setTimeout(() => navigate(`${ROUTES.REGISTER}?source=Premium Banner`), 0);

      return <></>;
    }

    navigate(`${ROUTES.PLANS}?source=Premium Banner`);
  };

  return (
    <div className={styles.subscription_link_wrapper}>
      <div className={styles.subscription_link_banner} onClick={redirectTo}>
        <div className={styles.subscription_badge_crown}>
          <img src={Crown} alt="" />
          <h5>First subscription</h5>
        </div>

        <div className={styles.subscription_badge_danger}>
          <h5>Up to 75% off</h5>
        </div>

        <div className={styles.subscription_badge_grey}>
          <h5>{dataPromotion}</h5>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
