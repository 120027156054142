import { Search } from "@shared/ui/Search";
import styles from "./styles.module.css";
import { useSearchParams } from "react-router-dom";
import { ButtonFactory } from "@shared/ui/Button";
import { IButtonTransfer, IGalleryFilter } from "@shared/interfaces";
import { useEffect, useState } from "react";
import Arrow from "@assets/arrow.svg";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import * as amplitude from "@amplitude/analytics-browser";

const FiltersBarActive: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const { fetchGalleryFilters } = useModelStore((state: IModelState) => state);
  const galleryFilters: IGalleryFilter = useModelStore(
    (state: IModelState) => state.galleryFilters
  );

  useEffect(() => {
    async function fetchAPI() {
      await fetchGalleryFilters();
    }

    fetchAPI();
  }, []);

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const defineActiveFilter = (value: string) => {
    const activeSearchParams = Object.fromEntries(searchParams.entries());
    const filterBy = activeSearchParams?.model_id;

    if (+filterBy === +value || (!filterBy && !value)) {
      return "danger";
    }

    return "primary";
  };

  const getFirstName = (fullName: string) => {
    return fullName.split(" ")[0];
  };

  const filters = galleryFilters.items.map((item) => ({
    name: getFirstName(item.name),
    value: item.id,
    count: item.images_count,
  }));

  filters.unshift({
    name: "All characters",
    value: "",
    count: galleryFilters.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.images_count,
      0
    ),
  });

  const onFilterClick = (e: IButtonTransfer) => {
    if (e.type === "") {
      searchParams.delete("model_id");
    } else {
      searchParams.set("model_id", e.type);
    }
    const active = filters.find((item) => item.value === e.type);

    amplitude.track(`Gallery Model Filter Applied`, {
      ...active,
    });

    setSearchParams(searchParams);
  };

  const renderFilters = () => {
    return filters.map((filter) => (
      <ButtonFactory
        key={filter.name}
        variant={defineActiveFilter(filter.value)}
        label=""
        type="button"
        value={filter.value}
        onClick={onFilterClick}
        children={
          <h5 className={`danger_btn-${defineActiveFilter(filter.value)}`}>
            {filter.name} {filter.count && <span>({filter.count})</span>}
          </h5>
        }
      />
    ));
  };

  return (
    <div className={styles.filter_bar_active_container}>
      <div
        className={styles.filter_bar_active_header}
        onClick={() => setDropDownVisible(!isDropDownVisible)}
      >
        <h4>Character Filter</h4>
        <div
          className={`${styles.header_action_content_arrow} ${
            isDropDownVisible && styles.header_action_content_arrow_revert
          }`}
        >
          <img src={Arrow} alt="user_profile_arrow" />
        </div>
      </div>

      {/* {isDropDownVisible && (
        <div className={styles.filter_bar_search_container}>
          <Search
            value={searchValue}
            onChange={onSearchChange}
            placeholder="Search"
          />
        </div>
      )} */}

      {isDropDownVisible && (
        <div className={styles.filter_bar_wrapper}>{renderFilters()}</div>
      )}
    </div>
  );
};

export default FiltersBarActive;
