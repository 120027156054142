import styles from "./styles.module.css";

interface IProps {
  label: string;
  children?: React.ReactNode;
  value?: string;
  onClick?: (event: { type: string }) => void;
}

const DangerButton = ({ label, onClick, children, value = "default_btn", ...rest }: IProps) => {
  if (children) {
    return (
      <button
        onClick={() => onClick && onClick({ type: value })}
        className={`${styles.danger_btn} ${styles.danger_btn_md}`}
        {...rest}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      onClick={() => onClick && onClick({ type: value })}
      className={`${styles.danger_btn} ${styles.danger_btn_md}`}
      {...rest}
    >
      {label}
    </button>
  );
};

export default DangerButton;
