import { initializeApp } from "firebase/app";
import{getAuth} from "firebase/auth";

const {
  REACT_APP_FB_API_KEY, 
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCKET,
  REACT_APP_FB_MESSAGING_SENDER_ID,
  REACT_APP_FB_APP_ID,
}  = process.env;

interface IFbConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}


const firebaseConfig: IFbConfig = {
  apiKey: REACT_APP_FB_API_KEY || '',
  authDomain: REACT_APP_FB_AUTH_DOMAIN || '',
  projectId: REACT_APP_FB_PROJECT_ID || '',
  storageBucket: REACT_APP_FB_STORAGE_BUCKET || '',
  messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID || '',
  appId:REACT_APP_FB_APP_ID || '',
};

// Initialize Firebase
const fbConnection = initializeApp(firebaseConfig);
export const auth = getAuth(fbConnection);

export default fbConnection;