import { DropDownMenu } from "@shared/ui/DropDown";
import { useState } from "react";

import styles from "./styles-header-menu-dd.module.css";
import Token from "@assets/token.svg";
import Plus from "@assets/plus.svg";
import PlusBlack from "@assets/plus_oval_black.svg";

import { LinkFactory } from "@shared/ui/Link";
import { ROUTES } from "@shared/interfaces";
import { IUserState, useUserStore } from "@entities/user";

const TokenMenuDropDown = () => {
  const [isDropDownShow, setDropDownShow] = useState(false);

  const tokens = useUserStore((state: IUserState) => state.user.tokens);

  document.body.className = isDropDownShow ? "token_menu_active" : "";

  const HEADER_MENU_LIST: any[] = [
    {
      title: "Image cost 2",
      type: "element",
      children: (
        <div className={styles.header_token_menu_item}>
          <h5>
            Image cost <span>2</span>
          </h5>
          <img src={Token} alt="cost_element" />
        </div>
      ),
    },
    {
      title: "Can I see...",
      type: "element",
      children: (
        <LinkFactory
          label=""
          type="danger"
          path={`${ROUTES.SUBSCRIBE}?source=Explore Page`}
        >
          <div className={styles.header_token_menu_item_bye}>
            <h5>Buy more</h5>
            <img src={PlusBlack} alt="plus_black" />
          </div>
        </LinkFactory>
      ),
    },
  ];

  const onClick = ({ type }: { type: string }) => {};

  const onVisibleChange = () => {
    setDropDownShow(!isDropDownShow);
  };

  const aroundTokens = (tokens: string) => {
    if (!tokens) return 0;

    return Math.round(parseFloat(tokens) * 100) / 100; //tokens.toFixed(2);
  };

  const children = (
    <div className={styles.header_token_container}>
      <img src={Token} alt="header_token" />
      <h5>
        Tokens <span>{aroundTokens(tokens?.toString() || "0")}</span>
      </h5>
      <img src={Plus} alt="header_plus" />
    </div>
  );

  return (
    <DropDownMenu
      active={""}
      isVisible={isDropDownShow}
      menuList={HEADER_MENU_LIST}
      callBack={onClick}
      onVisibleChange={onVisibleChange}
    >
      {children}
    </DropDownMenu>
  );
};

export default TokenMenuDropDown;
