import { clsx } from "clsx";
import styles from "./CharacterPromptArea.module.css";

type Props = {
  value?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
};

const CharacterPromptArea = ({ value = "", className, onChange }: Props) => {
  return (
    <div className={clsx(styles.root, className)}>
      <label className={styles.label} htmlFor="CharacterPromptArea">
        Enter prompt*
      </label>
      <textarea id="CharacterPromptArea" rows={6} className={styles.input} value={value} onChange={onChange}></textarea>
    </div>
  );
};

export default CharacterPromptArea;
