export const sortByDateDesc = (items: any[]) => {
  items.forEach((item: any) => {
    item.last_message.sortedTime = new Date(
      item.last_message.send_at
    ).getTime();
  });

  return items.sort((a, b) =>
    a.last_message.send_at > b.last_message.send_at
      ? -1
      : a.last_message.send_at > b.last_message.send_at
      ? 1
      : 0
  );
};
