import styles from "./styles_gi.module.css";
import CloseIcon from "@assets/close.svg";

interface IProps {
  url: string;
  onClose: () => void;
}

const GalleryItemPreview = ({ url, onClose }: IProps) => {
  const onCheckElement = (e: any) => {
    if (e.target.dataset?.source === "target") {
      onClose();
    }
  };
  return (
    <div className={styles.galleryitem_preview} onClick={onCheckElement}>
      <div className={styles.galleryitem_img}>
        <div
          className={styles.galleryitem_close_container}
          data-source="target"
        >
          <img src={CloseIcon} alt="galleryitem_close" data-source="target" />
        </div>
        <img src={url} alt="img_preview" className={styles.main_img} />
      </div>
    </div>
  );
};

export default GalleryItemPreview;
