import styles from "./styles.module.css";

const Benefits = () => {
  return (
    <div className={styles.benefits_container}>
      <div className={styles.benefits_content}>
        <h4>Token Benefits</h4>
        <ul>
          <li>Create AI Girlfriend(s)</li>
          <li>AI image generation</li>
        </ul>
      </div>
    </div>
  );
};

export default Benefits;
