import styles from './styles.module.css';

import Meta from "@assets/social/meta.svg";

const SocialMeta = () => {
  return (
    <div className={styles.social_component}>
      <img src={Meta} alt="social_meta"/>
      <h5>X</h5>
    </div>
  )
}

export default SocialMeta;