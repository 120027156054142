import styles from "./styles.module.css";
import User from "@assets/user_default.svg";
import Arrow from "@assets/arrow.svg";

interface IProps {
  isDropDownVisible: boolean;
  onClick: (payload: boolean) => void;
}

const HeaderActionContent = ({ isDropDownVisible, onClick }: IProps) => {
  return (
    <div
      onClick={() => onClick(!isDropDownVisible)}
      className={styles.header_action_content}
    >
      <div className={styles.header_action_content_img}>
        <img src={User} alt="user_profile" />
      </div>
      <h5>My Profile</h5>
      <div
        className={`${styles.header_action_content_arrow} ${
          isDropDownVisible && styles.header_action_content_arrow_revert
        }`}
      >
        <img src={Arrow} alt="user_profile_arrow" />
      </div>
    </div>
  );
};

export default HeaderActionContent;
