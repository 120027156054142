import Skeleton from 'react-loading-skeleton';
import styles from './styles-skeleton.module.css';

const ModelChatSkeleton = () => {
  return (
    <div className={styles.model_chat_header_skeleton}>
      <div><Skeleton circle width={40} height={40}/></div>
      <div className={styles.model_chat_header_skeleton_content}>
        <Skeleton style={{ borderRadius: "9px", height: "13px"}}/>
      </div>
    </div>
  )
}

export default ModelChatSkeleton;