import styles from './styles.module.css';

import Google from "@assets/social/google.svg";

const SocialGoogle = () => {
  return (
    <div className={styles.social_component}>
      <img src={Google} alt="social_google"/>
      <h5>Google</h5>
    </div>
  )
}

export default SocialGoogle;