import { ROUTES } from "@shared/interfaces";
import styles from "./styles_footer.module.css";
import LOGO_MAIN from "@assets/logo_main.svg";
import { Link } from "react-router-dom";
import Visa from "@assets/visa.svg";
import Master from "@assets/master.svg";

const Footer = () => {
  return (
    <div className={styles.footer_container}>
      <div className={styles.logo}>
        <img src={LOGO_MAIN} alt="main_logo" />
      </div>

      <div className={styles.legal}>
        <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
        <Link to={ROUTES.TERMS}>Terms</Link>
      </div>
      <div className={styles.copyright}>
        <h5>© 2024 GetFlirt.AI</h5>
        <div className={styles.payments}>
          <img src={Visa} alt="visa" />
          <img src={Master} alt="check" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
