import styles from "./styles.module.css";

interface IProps {
  type: string;
  name: string;
  value: string;
  error?: string | null;
  iconLeft?: string;
  iconRight?: string;
  placeholder: string;
  disabled?: boolean;
  onChange: (data: React.FormEvent) => void;
  onClick?: (event: { type: string }) => void;
}

export const InputItem = ({
  onClick,
  iconRight,
  iconLeft,
  onChange,
  error,
  disabled,
  ...props
}: IProps) => {
  const classes = ["input_wrapper", styles.input_wrapper];

  if (iconRight) {
    classes.push(styles.input_icon_right);
  }

  if (error) {
    classes.push(styles.input_error);
  }

  if (disabled) {
    classes.push(styles.input_disabled);
  }

  return (
    <div className={classes.join(" ")}>
      <input className={styles.input} onChange={onChange} {...props} />
      {iconRight && (
        <img
          src={iconRight}
          alt="input_icon_right"
          className={styles.input_icon_right}
          onClick={() => onClick && onClick({ type: "onRightClick" })}
        />
      )}
    </div>
  );
};

export default InputItem;
