import "rc-dropdown/assets/index.css";
import styles from "./styles.module.css";
import Dropdown from "rc-dropdown";
import Menu, { MenuItem } from "rc-menu";
import { AsideNavItem } from "@shared/ui/Link";
import { IAsideNavItem } from "@widgets/aside";

interface IProps {
  active?: string;
  isVisible: boolean;
  menuList: IAsideNavItem[];
  children: React.ReactElement;
  callBack?: (event: { type: string }) => any;
  onVisibleChange: (flag: boolean) => void;
}

const DropDownMenu: React.FC<IProps> = ({
  menuList,
  isVisible,
  children,
  callBack,
  onVisibleChange,
  active,
  ...props
}: IProps) => {
  const renderMenuContent = (item: IAsideNavItem) => {
    if (item.type === "element") {
      return item.children;
    }

    if (item.type === "link") {
      return <AsideNavItem item={item} isActive={false} className="nav_item" />;
    }

    return (
      <button className={`${styles.drop_down_item}`}>
        {item.icon && (
          <div className={styles.drop_down_item_img_container}>
            <img src={item.icon} alt={`menu_${item.title}`} />
          </div>
        )}
        <span>{item.title}</span>
      </button>
    );
  };

  const list = menuList.map((item: IAsideNavItem, index) => {
    const classes = [styles.drop_down_item];
    if (item.value === active) {
      classes.push("active");
    }

    if (item.type === "link") {
      return (
        <MenuItem className={classes.join(" ")} key={`item-menu-${index}`}>
          {renderMenuContent(item)}
        </MenuItem>
      );
    }

    return (
      <MenuItem
        className={classes.join(" ")}
        key={`item-menu-${index}`}
        onClick={() => callBack && callBack({ type: item?.value || "" })}
      >
        {renderMenuContent(item)}
      </MenuItem>
    );
  });

  const menu = <Menu className={"./"}>{list}</Menu>;

  return (
    <div>
      <Dropdown
        trigger={["click"]}
        overlay={menu}
        onVisibleChange={() => onVisibleChange(false)}
        visible={isVisible}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default DropDownMenu;
