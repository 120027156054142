import { useModelStore } from "@entities/model/model/model.store";
import styles from "./styles.module.css";
import { IModelState } from "@entities/model/model/model.types";
import { ModelInfoPersonalityItem } from "@features/model/ui";
import Personality from "@assets/user/personality.svg";
import Occupation from "@assets/user/occupation.svg";
import Hobbies from "@assets/user/hobbies.svg";
import Arrow from "@assets/arrow_nav.svg";
import ModelInfoSkeleton from "./ModelInfoSceleton";
import { useEffect } from "react";

interface IProps {
  setSteps?: (step: string) => void;
}

const ModelInfo: React.FC<IProps> = ({ setSteps }: IProps) => {
  useEffect(() => {
   const root = document.getElementById("root");
   
  }, [])

  const { model }: any = useModelStore(
    (state: IModelState) => state.modelActive
  );

  const isModelActiveLoading = useModelStore(
    (state: IModelState) => state.isModelActiveLoading
  );

  const renderModelName = () => {
    if (!model.name) {
      return;
    }

    return model.name + ", ";
  };

  if (isModelActiveLoading) {
    return <ModelInfoSkeleton />
  }

  return (
    <div className={styles.info_container}>
      <div
        style={{
          backgroundImage: `url("${model?.photo}")`,
        }}
        className={styles.info_ava}
      >
        <img
          src={Arrow}
          className={styles.modelInfo_chat_header_arrow}
          alt="user_profile_arrow"
          onClick={() => setSteps && setSteps("modelChat")}
        />
      </div>
      <div className={styles.model_description}>
        <h4>
          {renderModelName()}
          <span className={styles.model_age}>{model?.age}</span>
        </h4>
        <h6>{model?.description}</h6>

        <div className={styles.divider}></div>
        <div className={styles.model_about}>
          <h5>Personality Attributes:</h5>
        </div>
        <div className={styles.model_about_flex}>
          <ModelInfoPersonalityItem
            icon={Personality}
            title="PERSONALITY"
            description={model.personality}
          />

          <ModelInfoPersonalityItem
            icon={Occupation}
            title="OCCUPATION"
            description={model.occupation}
          />
        </div>
        <div>
          <ModelInfoPersonalityItem
            icon={Hobbies}
            title="HOBBIES"
            description={model.hobbies}
          />
        </div>
      </div>
    </div>
  );
};

export default ModelInfo;
