import styles from "./styles.module.css";
import Lottie from 'react-lottie';
import ProgressJSON from "../../../../../shared/assets/generate.json";
import HeartJSON from "../../../../../shared/assets/heart.json";
import { useEffect, useState } from "react";

interface IProps {
  icon: React.ReactElement;
  type?: string;
}

const PROGRESS_SPEED = 0.07

const ModelMessageLottie = ({ icon, type }: IProps) => {
  const classes = [styles.message_wrapper];
  const [duration, setDuration] = useState<boolean>(true);
  
  useEffect(() => {
    setTimeout(() => setDuration(true), 27000);
    setTimeout(() => setDuration(false), 10);
  },[])

  let content;

  const option = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const progressOption = {
    ... option, animationData: ProgressJSON
  }

  const heartOption = {
    ... option, animationData: HeartJSON
  }

  if (type === "generate") {
    content =  (
      <div className={styles.message_position}>
         <Lottie 
          options={progressOption}
          height={70}
          width={70}
          isPaused={duration}
          speed={PROGRESS_SPEED}
        />
        <div className={styles.heart}>
          <Lottie 
            options={heartOption}
            height={70}
            width={70}
          />
        </div>;
       
      </div>
    )
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.message_container}>
       {content}
      </div>
    </div>
  );
};

export default ModelMessageLottie;
