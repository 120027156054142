import styles from "./styles_stepper.module.css";

interface IProps {
  step: number;
}

const Stepper = ({ step }: IProps) => {
  const STEPS = 7;

  return (
    <div className={styles.stepper_container}>
      {[...Array(STEPS)].map((_, index) => (
        <div
          className={`${styles.stepper_item} ${
            step >= index + 1 ? styles.stepper_active : ""
          }`}
          key={index}
        ></div>
      ))}
    </div>
  );
};

export default Stepper;
