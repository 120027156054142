import styles from "./styles.module.css";
import { Aside } from "@widgets/aside";
import { FooterNav } from "@widgets/footer_nav";
import { Header } from "@widgets/header";
import { clsx } from "clsx";
import { useState } from "react";

type LayoutProps = React.PropsWithChildren<{
  className?: string;
}>;

const AsideStructure = ({ children, className }: LayoutProps) => {
  const [showAside, setShowAside] = useState(false);
  return (
    <>
      <Aside showAside={showAside} setShowAside={setShowAside} />
      <div className={clsx(styles.aside__children, className)}>
        <Header setShowAside={setShowAside} />
        {children}
      </div>
      <FooterNav />
    </>
  );
};

export default AsideStructure;
