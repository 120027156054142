import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

import PlusCircle from "@assets/plus_circle.svg";
import { ROUTES } from "@shared/interfaces";
import { useEffect, useState } from "react";
import { useCustomImageStore } from "@entities/customImage";
import GalleryItemFactory from "@features/gallery/GalleryItem.fabric";
import { Portal } from "@widgets/portal";
import { BecomePremium } from "@widgets/subscription";
import { IUserState, useUserStore } from "@entities/user";

const MyAiWidget = () => {
  const navigate = useNavigate();
  const { getCustomModels, customModels } = useCustomImageStore();
  const user = useUserStore((state: IUserState) => state.user);
  const [isBecomePremiumPopUpOpen, setIsBecomePremiumPopUpOpen] = useState(false);

  useEffect(() => {
    getCustomModels();
  }, []);

  const onClick = (flag: string, id: number | null) => {
    if (flag === "create") {
      return navigate(`${ROUTES.CREATE_AI}`);
    }

    if (customModels.length === 1 && user.plan === "free") {
      return setIsBecomePremiumPopUpOpen(true);
    }

    return navigate(`${ROUTES.CHAT}/${id}`);
  };

  return (
    <div className={styles.my_ai_wrapper}>
      {isBecomePremiumPopUpOpen && (
        <Portal className="portal_home">
          <BecomePremium onClose={() => setIsBecomePremiumPopUpOpen(false)} />
        </Portal>
      )}
      <h3 className={styles.my_ai_title}>My AI</h3>
      <div className={`${styles.my_ai_container} ${!customModels.length ? styles.my_ai_item_empty : ""}`}>
        <MyAiItem type="create" onClick={onClick} />
        {customModels.map((model, index) => (
          <MyAiItem key={index} type="model" model={model} onClick={onClick} />
        ))}
      </div>
    </div>
  );
};

interface IItemProps {
  type: string;
  model?: any;
  onClick: (flag: string, id: number | null) => void;
  className?: string;
}

const MyAiItem = ({ type, model, onClick, className }: IItemProps) => {
  if (type === "create") {
    return (
      <div className={`${styles.ai_item_create} ${className}`} onClick={() => onClick("create", null)}>
        <img src={PlusCircle} alt="PlusCircle" />
        <h3>Create New AI</h3>
      </div>
    );
  }

  return (
    <GalleryItemFactory
      model={model}
      variant="full_meta"
      key={model.id}
      onClick={() => onClick("checkPremium", model.id)}
    />
  );
};

export default MyAiWidget;
