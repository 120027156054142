import { FC } from "react";
import styles from "./styles.module.css";
import { ButtonFactory } from "@shared/ui/Button";
import CloseIcon from "@assets/close.svg";
import Congrat from "@assets/congrat.svg";

interface IProps {
  onCancel: () => void;
}

const AddTokensConfirmationPopup: FC<IProps> = ({ onCancel }) => {
  return (
    <div className={styles.success__container}>
      <div className={styles.succes__wrapper}>
        <img
          alt="close"
          src={CloseIcon}
          className={styles.close_icon}
          onClick={onCancel}
        />
        <div className={styles.congrat}>
          <img src={Congrat} alt="congrat" />
        </div>
        <h5 className={styles.success__title}>Tokens Added Successfully!</h5>
        <h5 className={styles.success__suptitle}>
          Thank you for your purchase! Your balance has been updated
        </h5>
        <div className={styles.actions__btn}>
          <ButtonFactory
            variant="danger"
            label="Continue"
            type="button"
            onClick={onCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default AddTokensConfirmationPopup;
