import styles from "./styles.module.css";
import ProfileAva from "@assets/profile_ava.svg";
import Edit from "@assets/edit.svg";
import Check from "@assets/check_red.svg";
import Trash from "@assets/trash.svg";
import { IUserState, useUserStore } from "@entities/user";
import { useEffect, useState } from "react";
import { Portal } from "@widgets/portal";
import { DropDownMenu } from "@shared/ui/DropDown";
import CheckActive from "@assets/check_active.svg";
import { ButtonFactory } from "@shared/ui/Button";
import Arrow from "@assets/arrow.svg";
import CloseIcon from "@assets/close.svg";
import * as amplitude from "@amplitude/analytics-browser";

export const MENU_LIST: any[] = [
  {
    title: "Male",
    value: "male",
    icon: CheckActive,
  },
  {
    title: "Female",
    value: "female",
    icon: CheckActive,
    activeKey: true,
  },
];

const Profile = () => {
  const { user, setUserChoice } = useUserStore((state: IUserState) => state);
  const [showPopup, setShowPopup] = useState(false);
  const [isDropDownShow, setDropDownShow] = useState(false);

  document.body.className = isDropDownShow ? "profile_filter_active" : "";

  const formState = {
    email: "",
    gender: "",
  };
  const [formData, setFormData] = useState<any>(formState);

  useEffect(() => {
    if (!user?.id || user.id === "initializing") {
      return;
    }

    setFormData({
      gender: modifyGender(user.gender_choice),
      email: user?.email,
    });
  }, [user.email]);

  const modifyGender = (prop: any) => {
    if (!prop) {
      return "";
    }

    return prop.replace("?type=", "");
  };

  const mapGender = (gender: string | undefined) => {
    if (!gender) {
      return "";
    }

    return gender.split("=")[1];
  };

  const onPopupOpen = (prop: string) => {
    switch (prop) {
      case "gender":
        PortalContent = GenderContent;
        break;

      default:
        break;
    }

    setShowPopup(true);
  };

  const defineActive = () => {
    const isActive = MENU_LIST.find((item) => item.value === modifyGender(formData.gender));

    if (isActive) {
      return isActive.value;
    }
  };

  const onClick = ({ type }: { type: string }) => {
    setFormData({ ...formData, gender: type });
    setDropDownShow(false);
  };

  const onSubmitClick = async () => {
    const modify = `?type=${formData.gender}`;

    await setUserChoice(modify);

    amplitude.track(`Settings Data Changed`, {
      changedField: "gender",
      oldValue: user?.gender_choice?.replace("?type=", ""),
      newValue: formData.gender,
    });

    localStorage.setItem("isStartedChoosing", modify);
    setShowPopup(false);
  };

  const GenderContent = (
    <div className={styles.settings_portal_gender}>
      <img
        alt="close"
        src={CloseIcon}
        className={styles.settings_portal_close_img}
        onClick={() => setShowPopup(!setShowPopup)}
      />
      <h1>Edit Profile</h1>
      <div className={styles.settings_portal_body_form_item}>{formData.email}</div>
      <div className={styles.settings_portal_body_input}>
        <DropDownMenu
          active={defineActive()}
          isVisible={isDropDownShow}
          menuList={MENU_LIST}
          callBack={onClick}
          onVisibleChange={() => setDropDownShow(!isDropDownShow)}
        >
          <div className={styles.settings_portal_body_form_item} onClick={() => setDropDownShow(true)}>
            {formData.gender}
            <img src={Arrow} alt="user_profile_arrow" />
          </div>
        </DropDownMenu>
      </div>

      <ButtonFactory type="button" label="Save changes" variant="danger" onClick={onSubmitClick} />
    </div>
  );

  let PortalContent = GenderContent;

  return (
    <div className={styles.profile_container}>
      {showPopup && (
        <Portal className="portal_home">
          <div className={styles.settings_portal_body}>{PortalContent}</div>
        </Portal>
      )}
      <div className={styles.profile_avatar_block}>
        <img src={ProfileAva} alt="profile_ava" className={styles.profile_avatar_img} />
        <div className={styles.profile_avatar_info}>
          <div className={styles.profile_avatar_info_left}>
            <div className={styles.profile_avatar_item}>
              <div className={styles.profile_avatar_item_title}>
                <h6>Legal name</h6>
                {/* <div className={styles.profile_avatar_item_edit}>
                  <img src={Edit} alt="edit" />
                </div> */}
              </div>
              <h5>
                {user.first_name} {user.last_name}
              </h5>
            </div>
            <div className={styles.profile_avatar_item}>
              <div className={styles.profile_avatar_item_title}>
                <h6>Email</h6>
                {/* <div className={styles.profile_avatar_item_edit}>
                  <img src={Edit} alt="edit" />
                </div> */}
              </div>
              <h5 className={styles.profile_avatar_item_email}>{user.email}</h5>
            </div>
            {/* <div className={styles.profile_avatar_item}>
              <div className={styles.profile_avatar_item_title}>
                <h5 className={styles.phone}>Phone Number</h5>
                <div className={styles.profile_avatar_item_edit}>
                  <img src={Edit} alt="edit" />
                </div>}
              </div>
            </div> */}
          </div>
          <div className={styles.profile_avatar_info_right}>
            <div className={styles.profile_avatar_item}>
              <div className={styles.profile_avatar_item_title}>
                <h6>Gender</h6>
                <div className={styles.profile_avatar_item_edit} onClick={() => onPopupOpen("gender")}>
                  <img src={Edit} alt="edit" />
                </div>
              </div>
              <h5 className={styles.profile_avatar_item_gender}>{mapGender(user?.gender_choice)}</h5>
            </div>
            <div className={styles.profile_avatar_item}>
              <div className={styles.profile_avatar_item_title}>
                <h6>Password</h6>
                {/* <div className={styles.profile_avatar_item_edit}>
                  <img src={Edit} alt="edit" />
                </div> */}
              </div>
              <h5>**********</h5>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.notifications}>
        <h5 className={styles.notifications_title}>Automatic Notifications</h5>
        <div className={styles.notifications_content}>
          <img src={Check} alt="check" />

          <h6>
            As a user, you will receive automatic notifications from us. If you
            don't want any notifications, uncheck the box by clicking on it.
          </h6>
        </div>
      </div> */}

      {/* <div className={styles.danger}>
        <div className={styles.danger_left}>
          <h5 className={styles.danger_title}>Danger Zone</h5>
          <div className={styles.danger_content}>
            <h6>
              If you want to permanently delete this account and all of its
              data.
            </h6>
          </div>
        </div>
        <div className={styles.danger_right}>
          <button onClick={() => {}}>
            Delete account
            <img src={Trash} alt="trash" />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Profile;
