import {
  FiltersBar,
  FiltersBarActive,
  Gallery,
  GalleryHeader,
} from "@widgets/gallery";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { useNavigate, useParams } from "react-router-dom";
import { IUserState, useUserStore } from "@entities/user";
import { ROUTES } from "@shared/interfaces";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";

const GalleryPage: React.FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const isAuth = useUserStore((state: IUserState) => state.user.id);

  useEffect(() => {
    amplitude.track(`Gallery Shown`);
  });

  if (!isAuth && !id) {
    setTimeout(
      () => navigate(`${ROUTES.REGISTER}?source=Side Menu Gallery`),
      0
    );

    return <></>;
  }

  return (
    <div className={styles.gallery_container}>
      <AsideStructure>
        <div className={styles.gallery_content}>
          <div className={styles.gallery_wrapper}>
            <FiltersBar />
          </div>
          <div className={styles.gallery_wrapper_filter}>
            <FiltersBarActive />
          </div>
          <div className={styles.gallery_content_wrapper}>
            <GalleryHeader />
            <Gallery />
          </div>
        </div>
      </AsideStructure>
    </div>
  );
};

export default GalleryPage;
