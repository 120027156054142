import { Button } from "@shared/ui/Button";
import { LeftBannerPopUp } from "@widgets/popup";
import { ReactComponent as Token } from "@shared/assets/tokenMd.svg";
import styles from "./NoTokensPopUp.module.css";

type Props = {
  isOpen?: boolean;
};

export function NoTokensPopUp({ isOpen = false }: Props) {
  return (
    <LeftBannerPopUp isOpen={isOpen}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Token />
        </div>

        <h3 className={styles.title}>Oops! Looks like we're out of tokens 😅</h3>

        <p className={styles.text}>Top up now, and I'll get back to creating amazing images for you!</p>

        <Button variant="primary" size="lg" fullWidth href="/subscribe">
          Get Tokens
        </Button>
      </div>
    </LeftBannerPopUp>
  );
}
