import { useState } from "react";
import styles from "./styles.module.css";
import ModelMessageSkeleton from "./ModelMessageSkeleton";
import { IUserState, useUserStore } from "@entities/user";
import { LinkFactory } from "@shared/ui/Link";
import { ROUTES } from "@shared/interfaces";

interface IProps {
  img: string | undefined;
  type?: string;
  isBlured?: boolean;
}

const ModelMessageImg = ({ img, type, isBlured }: IProps) => {
  const [isImgLoaded, setImgLoaded] = useState<boolean>(true);

  const onLoad = () => {
    setImgLoaded(false);
  };

  const classes = [styles.message_wrapper];

  if (!img) {
    return <></>;
  }

  if (type === "model") {
    classes.push(styles.message_model);
  }

  if (type === "user") {
    classes.push(styles.message_self);
  }

  if (isBlured) {
    classes.push(styles.message_blur);
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.message_container}>
        <LinkFactory
          className={[styles.link_plans]}
          label="Remove blur"
          type="danger"
          path={`${ROUTES.PLANS}`}
        />
        <div
          className={`${styles.message_wrapper}  ${
            isImgLoaded ? styles.img_loaded : ""
          }`}
        >
          <img
            src={img}
            alt="model_message_img"
            className={styles.message_img}
            onLoad={onLoad}
          />
          <div className={styles.model_card_skeleton}>
            <ModelMessageSkeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelMessageImg;
