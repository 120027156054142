export const getFormattedDate = () => {
  const date = new Date(); // Получаем текущую дату и время

  // Получаем компоненты даты и времени
  const year = String(date.getUTCFullYear()).padStart(4, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Форматируем строку
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
};
