
import { AuthBackground } from "@widgets/auth/background";
import { CreateAccountForm } from "@widgets/auth/createAccount";

const RegisterPage = () => {
  return (
    <AuthBackground>
      <CreateAccountForm />
    </AuthBackground>
  )
}

export default RegisterPage;