import { useState } from 'react';

const useForm = <T,>(
  formState: T, 
  callback: ()=> void,
) => {
  const [formData, setFormData] = useState<T>(formState);
  const onChangeInput = (e: any) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const resetFormData = () => {
    setFormData(formState);
  }

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    callback();
  };

  return {
    formData,
    onChangeInput,
    onSubmitForm,
    setFormData,
    resetFormData,
  };
};

export default useForm;