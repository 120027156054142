import styles from "./styles.module.css";

import LOGO_MAIN from "@assets/logo_main.svg";
import Female from "@assets/user/female_white.svg";
import Male from "@assets/user/male_white.svg";
import Anime from "@assets/user/anime_white.svg";
import FemaleBg from "@assets/user/female_interested_bg.png";
import MaleBg from "@assets/user/male_interested_bg.png";

import { IInterested, ROUTES, ROUTES_QUERY } from "@shared/interfaces";
import { InterestedItem } from "@features/user";
import * as amplitude from "@amplitude/analytics-browser";
import { memo, useEffect, useState } from "react";

const INTERESTED: IInterested[] = [
  {
    title: "Girls",
    icon: Female,
    background: FemaleBg,
    path: `type=${ROUTES_QUERY.female}`,
  },
  {
    title: "Guys",
    icon: Male,
    background: MaleBg,
    path: `type=${ROUTES_QUERY.male}`,
  },
  // {
  //   title: 'Anime girls',
  //   icon: Anime,
  //   background: MaleBg,
  //   path: `type=${ROUTES_QUERY.anime_female}`,
  // },
  // {
  //   title: 'Anime guys',
  //   icon: Anime,
  //   background: MaleBg,
  //   path: `type=${ROUTES_QUERY.anime_male}`,
  // }
];

const Interested: React.FC = memo(() => {
  const redirect = (path: string) => {
    const trackEvent = path.split("=")[1];

    amplitude.track(`Gender Interest Selection Pop Up Shown`);
    amplitude.track(`Gender of Interest Selected `, {
      gender: trackEvent,
    });

    localStorage.setItem("isStartedChoosing", `?${path}`);
    window.location.replace(`${ROUTES.HOME}?${path}`);
  };

  return (
    <div className={styles.interested_container}>
      <div className={styles.interested_content}>
        <img
          className={styles.interested_logo}
          src={LOGO_MAIN}
          alt="main_logo_interested"
        />

        <h3>I'm Interested in:</h3>

        <div className={styles.interested_wrapper}>
          {INTERESTED.map((interest: IInterested) => (
            <InterestedItem
              item={interest}
              onClick={(path) => redirect(path)}
              key={`interest_${interest.title}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default Interested;
