interface IValidationInstance {
  field: string,
  title: string,
}

export class Validation<T> {
  instance: IValidationInstance;
  error: string[];

  constructor(validationObject :IValidationInstance) {
    this.instance = validationObject;
    this.error = [];
  }

  isRequired() {
    if (!this.instance.field) {
      this.error = [...this.error, `${this.instance.title} is required.`];
    }

    return this;
  }

  isMoreThanZero() {
    if (!this.instance.field) {
      this.error = [
        ...this.error,
        `${this.instance.title} must be greater than 0`,
      ];
    }

    return this;
  }

  isValidCharacters() {
    if (!/^[A-ZА-Я0-9._-]+$/i.test(this.instance.field)) {
      this.error = [...this.error, 'Unacceptable symbols.'];
    }

    return this;
  }

  isEmail() {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.instance.field)) {
      this.error = [...this.error, `${this.instance.title} is not valid.`];
    }
    return this;
  }

  minLength(value: number) {
    if (this.instance.field.length < value) {
      this.error = [
        ...this.error,
        `Please enter at least ${value} characters.`,
      ];
    }

    return this;
  }

  maxLength(value: number) {
    if (this.instance.field && this.instance.field.length > value) {
      this.error = [
        ...this.error,
        `Your ${this.instance.title} is too long. Max - ${value}`,
      ];
    }

    return this;
  }

  fileNameLength(fileName: string, value: number) {
    if (fileName?.length > value) {
      this.error = [
        ...this.error,
        `${this.instance.title} name must not exceed 50 letters.`,
      ];
    }

    return this;
  }

  containLettersAndNumbers() {
    const regexpNumbers = new RegExp(/[0-9]/, 'g');
    const regexpLetters = new RegExp(/[a-zA-Z]/, 'g');

    const consistNumber = regexpNumbers.test(this.instance.field);
    const consistString = regexpLetters.test(this.instance.field);

    if (!consistNumber || !consistString) {
      this.error = [
        ...this.error,
        `Your ${this.instance.title} must contain letters and numerals`,
      ];
    }

    return this;
  }

  // canNotBeZero() {
  //   if (this.instance.field <= 0) {
  //     this.error = [...this.error, `${this.instance.title}`];
  //   }

  //   return this;
  // }

  getErrors() {
    return this.error;
  }
}
