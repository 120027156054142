import styles from "./styles.module.css";
import { HeaderNav } from "@widgets/header_nav";
import { HeaderActions } from "@widgets/header_actions";

interface IProps {
  setShowAside: (flag: boolean) => void;
}

const Header: React.FC<IProps> = ({ setShowAside }: IProps) => {
  return (
    <header className={styles.header_container}>
      <HeaderNav setShowAside={setShowAside} />
      <HeaderActions />
    </header>
  );
};

export default Header;
