import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { IHeaderNavItem } from "@widgets/header_nav";
import { ROUTES } from "@shared/interfaces";

interface IProps {
  item: IHeaderNavItem;
  isActive: boolean;
  pathname: string;
}

const HeaderNavItem: React.FC<IProps> = ({
  isActive,
  item,
  pathname,
}: IProps) => {
  let pathTo = `${pathname}?${item.path}`;

  if (pathname === ROUTES.HOME) {
    pathTo = `?${item.path}`;
  }

  return (
    <Link
      to={pathTo}
      className={`${styles.header_link_wrapper} ${
        isActive && styles.header_link_active
      }`}
    >
      <div className={styles.header_link_img_container}>
        <img
          src={isActive ? item.activeIcon : item.icon}
          alt={`aside_${item.title}`}
        />
      </div>
      <span>{item.title}</span>
    </Link>
  );
};

export default HeaderNavItem;
