import styles from "./styles.module.css";

import LOGO_MAIN from "@assets/logo_main.svg";
import NotFoundLogo from "@assets/404.svg";
import { ROUTES } from "@shared/interfaces";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className={styles.not_found_container}>
      <img className={styles.not_found_logo} src={LOGO_MAIN} alt="logo_main" />
      <img className={styles.not_found_404} src={NotFoundLogo} alt="logo_404" />

      <h1>Oops! Page Not Found</h1>
      <h5>
        The page you’re looking for doesn’t exist. Head back to our homepage to
        continue exploring our site
      </h5>

      <Link className={styles.not_found_link} to={ROUTES.HOME}>
        Go to Home page
      </Link>
    </div>
  );
};

export default NotFound;
