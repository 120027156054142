import { FC } from "react";
import styles from './styles.module.css';
import { ButtonFactory } from "@shared/ui/Button";
import CloseIcon from '@assets/close.svg';

interface IProps {
  name: string;
  onCancel: () => void,
  onDelete: () => void,
}

const DeletePopup:FC<IProps> = ({name, onCancel, onDelete}) => {
  return (
    <div className={styles.delete__container}>
      <div className={styles.delete__wrapper}>
      <img
          alt="close"
          src={CloseIcon}
          className={styles.close_icon}
          onClick={onCancel}
        />
        <h3 className={styles.delete__title}>Delete сhat</h3>
        <h5 className={styles.delete__suptitle}>
          Are you sure you want to delete the conversation 
          with {name}? 
          All messages will be lost.
        </h5>

        <div className={styles.actions__btn}>
          <ButtonFactory
              variant="primary"
              label="Cancel"
              type="button"
              onClick={onCancel}
            />

          <ButtonFactory
            variant="danger"
            label="Delete"
            type="button"
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  )
}

export default DeletePopup;