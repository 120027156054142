import { Button } from "@shared/ui/Button";
import { ReactComponent as Check } from "@shared/assets/check.svg";
import styles from "./PremiumPopUp.module.css";
import { LeftBannerPopUp } from "@widgets/popup";

type Props = {
  isOpen?: boolean;
};

const PREMIUM_FEATURES = [
  "Chat Unlimited With Your AI",
  "Ask Her To Send You The Most Spicy Photos",
  "Generate As Many AI's As You Want",
];

export function PremiumPopUp({ isOpen = false }: Props) {
  return (
    <LeftBannerPopUp isOpen={isOpen}>
      <h3 className={styles.title}>
        Become
        <br />
        <span className={styles.title__sub}>Premium to chat</span>
      </h3>
      <ul className={styles.list}>
        {PREMIUM_FEATURES.map((feature) => (
          <li key={feature} className={styles.list__item}>
            <Check className={styles.list__item__icon} />
            {feature}
          </li>
        ))}
      </ul>

      <Button variant="primary" size="lg" fullWidth href="/plans">
        Become Premium
      </Button>
    </LeftBannerPopUp>
  );
}
