
import Skeleton from 'react-loading-skeleton';
import styles from './styles-skeleton.module.css';
import { FC } from 'react';

interface IProps {
  cards: number;
}

const ChatBarSkeleton: FC<IProps> = ({ cards }: IProps) => {
  return (
    <>    
    {
      Array(cards).fill(0).map((card, index )=>  (
        <div className={styles.chat_bar_skeleton} key={`skelleton-${index}`}>
          <div className={styles.left_collumn}>
            <Skeleton circle width={40} height={40}/>
          </div>

          <div className={styles.right_collumn}>
            <Skeleton count={2} style={{marginBottom: "6px", borderRadius: "9px", height: "18px"}}/>
          </div>
        </div>
      ))
    }
    </>

  )
}

export default ChatBarSkeleton;