import { IUserState, useUserStore } from "@entities/user";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { Benefits, Bonuses, Event } from "@widgets/plans";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";

const SubscribePage = () => {
  const navigate = useNavigate();
  const isAuth = useUserStore((state: IUserState) => state.user.id);

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const source = urlParams.get("source");
    amplitude.track(`Premium Plans Page Shown`, {
      source,
    });

    const param = urlParams.get("parent");

    amplitude.track(`Upgrade to Premium Clicked`, {
      source: param,
    });
  }, []);

  if (!isAuth) {
    setTimeout(
      () => navigate(`${ROUTES.REGISTER}?source=Side Menu Premium`),
      0
    );

    return <></>;
  }

  return (
    <div className={styles.subscribe_container}>
      <AsideStructure>
        <div className={styles.subscribe_content}>
          <h1 className={styles.subscribe_title}>Choose your plan</h1>
          <h4 className={styles.subscribe_suptitle}>
            100% anonymous. <span>You can cancel anytime</span>
          </h4>
          <div className={styles.subscribe_wrapper}>
            <Event />
            <Bonuses />
            <Benefits />
          </div>
        </div>
      </AsideStructure>
    </div>
  );
};

export default SubscribePage;
