import { clsx } from "clsx";
import { ReactComponent as CheckmarkCircle } from "@shared/assets/checkmark_circle.svg";
import styles from "./SignUpNote.module.css";
import { JSONParse } from "@shared/libs";

type Props = {
  className?: string;
};

const SignUpNote = ({ className }: Props) => {
  const customImageData = JSONParse<{
    user_prompt: string;
    model_id?: number;
  }>(window.localStorage.getItem("customImage") ?? "");

  const createdAi = JSONParse<{
    ai: string;
  }>(window.localStorage.getItem("createdAi") ?? "");

  if (!customImageData?.model_id && !createdAi) {
    return null;
  }

  return (
    <div className={clsx(styles.root, className)}>
      <CheckmarkCircle className={styles.icon} />
      <span className={styles.label}>Your choices will be saved</span>
    </div>
  );
};

export default SignUpNote;
