import axios, { AxiosInstance } from "axios";
interface AxiosConfig {
  baseURL?: string;
  headers?: Record<string, string>;
}

export const axiosConfigBuilder = (config: AxiosConfig = {}) => {
  const setBaseUrl = (baseURL?: string) => {
    const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    if (!baseURL) {
      baseURL = APP_BASE_URL;
    }

    return axiosConfigBuilder({ ...config, baseURL });
  };

  const setHeaders = (headers: Record<string, string>) => {
    return axiosConfigBuilder({
      ...config,
      headers: { ...config.headers, ...headers },
    });
  };

  const build = (): AxiosInstance => {
    return axios.create(config);
  };

  return {
    setBaseUrl,
    setHeaders,
    build,
  };
};
