import { clsx } from "clsx";
import styles from "./OutlinedButton.module.css";

type Props = {
  children?: React.ReactNode;
  value?: string;
  onClick?: (event: { type: string }) => void;
};

export function OutlinedButton({
  onClick,
  children,
  value = "default_btn",
  ...rest
}: Props) {
  return (
    <button
      onClick={() => onClick && onClick({ type: value })}
      className={clsx(styles.outlined_btn, styles.outlined_btn_md)}
      {...rest}
    >
      {children}
    </button>
  );
}
