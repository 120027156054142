import styles from "./created-ai.module.css";
import { ROUTES } from "@shared/interfaces";
import CloseIcon from "@assets/close_gray.svg";
import UpdatePlanImg from "@assets/ai_created_banner.jpg";
import Personality from "@assets/user/personality.svg";
import Occupation from "@assets/user/occupation.svg";
import Hobbies from "@assets/user/hobbies.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonFactory } from "@shared/ui/Button";
import { useCustomImageStore } from "@entities/customImage";
import { Portal } from "@widgets/portal";
import { BecomePremium } from "@widgets/subscription";
import { useState } from "react";
import { IUserState, useUserStore } from "@entities/user";

interface IProps {
  onClose?: () => void;
  model: any;
}

const CreatedAiDialog = ({ onClose, model }: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { customModels } = useCustomImageStore();
  const user = useUserStore((state: IUserState) => state.user);
  const [isBecomePremiumPopUpOpen, setIsBecomePremiumPopUpOpen] = useState(false);

  const toChat = () => {
    if (customModels.length === 1 && user.plan === "free") {
      return setIsBecomePremiumPopUpOpen(true);
    }

    localStorage.removeItem("createdAi");
    if (id) {
      navigate(`${ROUTES.CHAT}/${id}?source=Created AI Popup`);
    }
  };

  const toMyAi = () => {
    if (customModels.length === 1 && user.plan === "free") {
      return setIsBecomePremiumPopUpOpen(true);
    }

    localStorage.removeItem("createdAi");
    navigate(`${ROUTES.CREATE_AI}?source=Created AI Popup`);
  };

  return (
    <div className={styles.createdAi_container}>
      {isBecomePremiumPopUpOpen && (
        <Portal className="portal_home">
          <BecomePremium onClose={() => setIsBecomePremiumPopUpOpen(false)} />
        </Portal>
      )}
      <div className={styles.createdAi_wrapper}>
        <div className={styles.createdAi_top}>
          <div className={styles.createdAi_bg}>
            <img src={model.photo ? model.photo : UpdatePlanImg} alt="bg_img" />
          </div>

          <div className={styles.createdAi_right}>
            <img src={CloseIcon} className={styles.createdAi_close} alt="close" onClick={onClose} />

            {model?.name && (
              <div className={styles.createdAi_name}>
                <h4 className={styles.createdAi_title}>Name:</h4>
                <h5 className={styles.createdAi_name}>{model?.name || ""}</h5>
              </div>
            )}

            <div className={styles.createdAi_bio}>
              <h4 className={styles.createdAi_title}>BIO:</h4>
              <h5 className={styles.createdAi_text}>{model?.description || ""}</h5>
            </div>

            <div className={styles.createdAi_actions}>
              <ButtonFactory label="Start Chatting" onClick={toChat} variant="danger" />

              <ButtonFactory label="Create New" onClick={toMyAi} variant="danger" />
            </div>

            <div className={styles.createdAi_private}>Your AI will remain private. Only you will see it</div>
          </div>
        </div>
        <div className={styles.createdAi_bottom}>
          <div className={styles.createdAi_about}>
            <h5>Personality Attributes:</h5>
            <div className={styles.createdAi_personality_container}>
              <ModelInfoPersonalityItem icon={Personality} title="OCCUPATION" description={model.occupation} />
              <ModelInfoPersonalityItem icon={Occupation} title="HOBBIES" description={model.hobbies} />
            </div>
            <ModelInfoPersonalityItem icon={Hobbies} title="SEXUAL BEHAVIOUR" description={model.personality} />
          </div>

          <div className={styles.createdAi_about}>
            <h5>Personality Attributes:</h5>
            <div className={styles.createdAi_personality_container}>
              <ModelInfoPersonalityItem icon={Personality} title="BODY" description={model.body_type} />
              <ModelInfoPersonalityItem icon={Occupation} title="AGE" description={model.age} />
            </div>
            <ModelInfoPersonalityItem icon={Hobbies} title="ETHNICITY" description={model.ethnicity} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatedAiDialog;

interface IPersonality {
  icon: string;
  title: string;
  description: string;
}
const ModelInfoPersonalityItem = ({ icon, title, description }: IPersonality) => {
  return (
    <div className={styles.info_personality_container}>
      <div className={styles.info_personality_img}>
        <img src={icon} alt="info_personality" />
      </div>
      <div className={styles.info_personality_wrapper}>
        <h6 className={styles.info_personality_title}>{title}</h6>
        <h6 className={styles.info_personality_desc}>{description}</h6>
      </div>
    </div>
  );
};
