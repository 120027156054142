import { Link } from "react-router-dom";
import { clsx } from "clsx";
import styles from "./Button.module.css";

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  variant?: "primary" | "secondary" | "danger" | "outlined";
  size?: "sm" | "md" | "lg";
  fullWidth?: boolean;
  href?: string;
  isLoading?: boolean;
};

export function Button({
  variant = "secondary",
  size = "md",
  children,
  type = "button",
  className,
  fullWidth = false,
  isLoading = false,
  href,
  ...restProps
}: Props) {
  const classes = clsx(
    styles.button,
    [styles[`button--${variant}`]],
    [styles[`button--${size}`]],
    { [styles["button--full-width"]]: fullWidth },
    className
  );

  if (href) {
    return (
      <Link to={href} className={classes}>
        {isLoading ? <span className={styles.loader}></span> : children}
      </Link>
    );
  }

  return (
    <button className={classes} type={type} {...restProps}>
      {isLoading ? <span className={styles.loader}></span> : children}
    </button>
  );
}
