import styles from "./styles_heir_styles.module.css";

import Straight from "@assets/constructor/ethnicity/straight.png";
import Braids from "@assets/constructor/ethnicity/braids.png";
import Bangs from "@assets/constructor/ethnicity/bangs.png";
import Curly from "@assets/constructor/ethnicity/curly.png";
import Bun from "@assets/constructor/ethnicity/bun.png";
import Short from "@assets/constructor/ethnicity/short.png";
import Long from "@assets/constructor/ethnicity/long.png";
import Ponytail from "@assets/constructor/ethnicity/ponytail.png";
import Pigtails from "@assets/constructor/ethnicity/pigtails.png";
import Afro from "@assets/constructor/ethnicity/afro_h.png";
import { IPersonalityKey } from "@shared/interfaces";
import * as amplitude from "@amplitude/analytics-browser";
import { IUserState, useUserStore } from "@entities/user";

interface IProps {
  hair: IPersonalityKey;
  hairColor: string;
  hairChoose: (data: IPersonalityKey) => void;
  hairColorChoose: (data: string) => void;
  validationError: any;
}

interface IPersonality {
  title: string;
  value: string;
  icon?: string;
}

const HAIR: IPersonality[] = [
  {
    title: "Straight",
    value: "Straight",
    icon: Straight,
  },
  {
    title: "Braids",
    value: "Braids",
    icon: Braids,
  },
  {
    title: "Bangs",
    value: "Bangs",
    icon: Bangs,
  },
  {
    title: "Curly",
    value: "Curly",
    icon: Curly,
  },
  {
    title: "Bun",
    value: "Bun",
    icon: Bun,
  },
  {
    title: "Short",
    value: "Short",
    icon: Short,
  },
  {
    title: "Long",
    value: "Long",
    icon: Long,
  },
  {
    title: "Ponytail",
    value: "Ponytail",
    icon: Ponytail,
  },
  {
    title: "Pigtails",
    value: "Pigtails",
    icon: Pigtails,
  },
  {
    title: "Afro",
    value: "Afro",
    icon: Afro,
  },
];

const HAIR_COLOR: IPersonality[] = [
  {
    title: "Blonde",
    value: "Blonde",
  },
  {
    title: "Brunette",
    value: "Brunette",
  },
  {
    title: "Black",
    value: "Black",
  },
  {
    title: "Pink",
    value: "Pink",
  },
  {
    title: "Redhead",
    value: "Redhead",
  },
];

const ChooseHeirStyle = ({ hair, hairColor, hairChoose, hairColorChoose, validationError }: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);

  const onHairChoose = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Hair Style Selected`, {
      userType: user.plan,
    });

    hairChoose(value);
  };
  const onHairColorChoose = (value: string) => {
    amplitude.track(`Constructor Hair Color, Selected`, {
      userType: user.plan,
    });

    hairColorChoose(value);
  };
  return (
    <div className="">
      <div className={`${validationError?.hair_style && styles.createAi_error}`}>
        <h4 className={styles.hair_title}>Choose Hair Style</h4>
        <div className={styles.hair_container}>
          {HAIR.map((item, index) => (
            <HairItem key={index} item={item} hair={hair} hairChoose={(value) => onHairChoose(value)} />
          ))}
        </div>
      </div>

      <div className={`${validationError?.hair_color && styles.createAi_error}`}>
        <h4 className={styles.hair_title}>Choose Hair Color</h4>
        <div className={styles.hair_color_container}>
          {HAIR_COLOR.map((item, index) => (
            <HairColorItem
              key={index}
              item={item}
              hairColor={hairColor}
              hairColorChoose={(value) => onHairColorChoose(value)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

interface IPersonalityItemProps {
  item: IPersonality;
  hair: IPersonalityKey;
  hairChoose: (data: IPersonalityKey) => void;
}

const HairItem = ({ item, hair, hairChoose }: IPersonalityItemProps) => {
  return (
    <div
      className={`${styles.hair_item} ${hair.value === item.value && styles.hair_active}`}
      onClick={() => hairChoose({ value: item.value, icon: item?.icon || "" })}
    >
      <img src={item.icon} alt={item.value} />
      <div className={styles.hair_badge}>{item.title}</div>
    </div>
  );
};

interface IPersonalityColorItemProps {
  item: IPersonality;
  hairColor: string;
  hairColorChoose: (data: string) => void;
}

const HairColorItem = ({ item, hairColor, hairColorChoose }: IPersonalityColorItemProps) => {
  return (
    <div
      className={`${styles.hair_color_item} ${styles[item.value]} ${
        hairColor === item.value ? styles.color_active : ""
      }`}
      onClick={() => hairColorChoose(item.value)}
    >
      {item.title}
    </div>
  );
};

export default ChooseHeirStyle;
