import AskToGenerateImg from "@assets/ask_to_generate.svg";
import styles from "./styles.module.css";
import Info from "@assets/info_yelow.svg";
import AskArrow from "@assets/ask_arrow.svg";

const AskToGenerate = () => {
  return (
    <div className={styles.ask_to_gen_container}>
      <img className={styles.info} src={Info} alt="ask_to_info" />
      <img src={AskToGenerateImg} alt="ask_to_generate" />
      <img className={styles.arrow} src={AskArrow} alt="ask_arrow" />
    </div>
  );
};

export default AskToGenerate;
