import { useEffect } from "react";
import { createPortal } from "react-dom";

interface IProps {
  children: React.ReactElement,
  className?: string,
}

const Portal = ({
  children,
  className
}: IProps) => {
  const mount = document.getElementById("portal-root");
  const el = document.createElement("div");
  const classes = [];

  if (className) {
    classes.push(className)
  }

  useEffect(()=>{
    mount?.appendChild(el);
  
    return () => {
      mount?.removeChild(el);
    }
  }, [el, mount]);

  if (classes.length){
    mount?.classList.add(classes.join(" "));
  }

  return createPortal(children, el)
};


export default Portal;