import styles from './styles.module.css';

import Discord from "@assets/social/discord.svg";

const SocialGoogle = () => {
  return (
    <div className={`${styles.social_component} ${styles.social_discord}`}>
      <img src={Discord} alt="social_discord"/>
      <h5>Discord</h5>
    </div>
  )
}

export default SocialGoogle;