import { ROUTES } from "@shared/interfaces";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { ButtonFactory } from "@shared/ui/Button";

interface IProps {
  onClose: () => void;
}

const Cookie = ({ onClose }: IProps) => {
  const onclick = () => {
    window.location.replace(`${ROUTES.TERMS}`);
  };

  return (
    <div className={styles.cookie_container}>
      <div className={styles.cookie_wrapper}>
        <h1 className={styles.cookie_title}>We use cookies</h1>
        <h5 className={styles.cookie_subtitle}>
          We use cookies to provide you with a smooth browsing experience,
          personalize content, improve our website, and do other things
          described in our Cookie Policy.
          <span className={styles.danger} onClick={onclick}>
            Read more
          </span>
        </h5>
        <ButtonFactory
          variant="danger"
          label="Accept All"
          type="button"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default Cookie;
