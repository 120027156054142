import { IUserState, useUserStore } from "@entities/user";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { Benefits, Bonuses, Event } from "@widgets/plans";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { useEffect, useLayoutEffect, useRef } from "react";
import * as amplitude from "@amplitude/analytics-browser";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const isAuth = useUserStore((state: IUserState) => state.user.id);
  const top = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    document.getElementById("root")?.children[0]?.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.privacy_container}>
      <div ref={top} />
      <AsideStructure>
        <div className={styles.privacy_wrapper}>
          <h2 className={styles.title}>Privacy Policies</h2>
          <h5 className={styles.subtitle}>Last Modified: October 25, 2024</h5>
          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>1. Welcome!</h5>
            <h5 className={styles.par_text}>
              InnoAI Development, a company registered in Cyprus ("GetFlirt.AI"
              or "We") with a principal office at 29 Agiou Pavlou, Nicosia 1107,
              Cyprus, takes your privacy seriously. This Privacy Policy
              (“Policy”) explains how we collect, use, and share your Personal
              Information, your Rights & Choices, and other essential
              information about our practices.
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>2. Scope of This Policy</h5>
            <h5 className={styles.par_text}>
              <span>
                This Policy applies to your (“you”) use of the GetFlirt.AI
                website (getflirt.ai), including all content available on the
                site, the GetFlirt.AI mobile application via the App Store or
                Google Play (the “App(s)”), or by accessing related services
                provided by GetFlirt.AI (collectively, the “Service”).{" "}
              </span>
              <span>
                IMPORTANT: PLEASE READ OUR PRIVACY POLICY CAREFULLY BEFORE USING
                THE SERVICE. BY USING THE SERVICE, YOU ACCEPT THIS PRIVACY
                POLICY IN FULL. IF YOU DO NOT AGREE TO THE POLICY, DO NOT USE
                THE SERVICE.
              </span>
              <span>
                By using the Service, you confirm that (a) you have read and
                understood this Policy, (b) you accept it and consent to the
                collection, use, and sharing of your Personal Information as
                described herein, and (c) you agree to receive notifications
                about security incidents via the email on file.
              </span>
              <span>
                Please note that your use of our Service is also subject to our
                Terms of Use and Community Guidelines. If this Policy changes,
                we will notify you if required. Your continued use of our
                Service constitutes acceptance of any revised Policy.
              </span>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>3. How to Contact Us</h5>
            <h5 className={styles.par_text}>
              General Inquiries: contact@getflirt.ai
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>4. Age Policy</h5>
            <h5 className={styles.par_text}>
              GetFlirt.AI is intended for individuals aged 18 and over. We do
              not knowingly collect data from or market to individuals under 18.
              If we become aware of any data from individuals under 18, we will
              promptly delete it as required by law.
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>
              5. Collection of Personal Information
            </h5>
            <h5 className={styles.par_text}>
              <ul className={styles.list}>
                <span className={styles.par_ul}>
                  We may collect the following categories of Personal
                  Information:
                </span>
                <li>
                  Audio/Visual Data: Videos, images, and recordings, including
                  avatars and character photos.
                </li>
                <li>
                  Contact Data: Contact information like email, address, and
                  phone number.
                </li>
                <li>
                  Device/Network Data: Data from your device, such as IP
                  address, cookies, and session history
                </li>
                <li>
                  Financial Data: Information relevant to transactions, like
                  payment details.
                </li>
                <li>General Location Data: Derived from IP addresses.</li>
                <li>Health Data: Information about your health.</li>
                <li>
                  Identity Data: Name, gender, date of birth, account details.
                </li>
                <li>Interface Data: Preferences, likes, and interests.</li>
                <li>
                  Transaction Data: Records of purchases and transactions with
                  us.
                </li>
                <li>
                  User Content: Content you provide through interactions,
                  messages, or uploads.
                </li>
                <li>
                  Consumer Health Data: As defined under applicable laws; please
                  see our Consumer Health Data Privacy Policy.
                </li>
                <li>
                  How We Collect Personal Information: We may collect Personal
                  Information directly from you, automatically through cookies,
                  or from third-party service providers and social media
                  platforms.
                </li>
              </ul>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>6. Use of Personal Information</h5>
            <h5 className={styles.par_text}>
              <ul className={styles.list}>
                <span className={styles.par_ul}>
                  We may use your Personal Information to:
                </span>
                <li>Operate and improve our Service;</li>
                <li>Ensure security and analyze usage;</li>
                <li>Enhance user experience, including staying logged in;</li>
                <li>Comply with legal obligations;</li>
                <li>Serve advertisements, if applicable.</li>
                <li>
                  We also use cookies and similar tracking technologies to
                  maintain sessions, understand navigation patterns, and enable
                  specific functionalities.
                </li>
              </ul>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>7. Purpose of Use</h5>
            <h5 className={styles.par_text}>
              <ul className={styles.list}>
                <span className={styles.par_ul}>
                  In addition to the above, we may use your Personal Information
                  for:
                </span>
                <li>
                  Business Purposes: Including account maintenance, customer
                  service, analytics, security, and improvement of our Service.
                </li>
                <li>
                  Commercial Purposes: Marketing, Online Advertising, and
                  sending promotional communications. We may use data to enhance
                  your experience on GetFlirt.AI and target advertisements or
                  recommendations relevant to you.
                </li>
              </ul>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>8. Data Sharing</h5>
            <h5 className={styles.par_text}>
              <ul className={styles.list}>
                <span className={styles.par_ul}>
                  We may share your Personal Information with:
                </span>
                <li>
                  Vendors and Service Providers: For essential operations and
                  service improvements.
                </li>
                <li>
                  Corporate Events: If GetFlirt.AI undergoes a merger,
                  acquisition, or sale of assets
                </li>
                <li>
                  Legal Disclosures: To comply with legal requirements or to
                  protect our Service.
                </li>
              </ul>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>9. Your Rights & Choices</h5>
            <h5 className={styles.par_text}>
              <ul className={styles.list}>
                <span className={styles.par_ul}>
                  You may have rights related to your Personal Information under
                  applicable law, including the rights to:
                </span>
                <li>
                  Access or request deletion of your Personal Information;
                </li>
                <li>Withdraw consent for certain uses</li>
                <li>Manage cookies or opt out of targeted advertising.</li>
                <li>
                  For requests or inquiries, please contact us at
                  contact@getflirt.ai.
                </li>
              </ul>
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>10. Security</h5>
            <h5 className={styles.par_text}>
              We implement security measures to protect your Personal
              Information. However, no method of transmission or storage is
              entirely secure, and we cannot guarantee absolute protection.
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>11. Data Retention</h5>
            <h5 className={styles.par_text}>
              We retain Personal Information for as long as necessary to fulfill
              its purpose or as required by law. Retention durations are
              reviewed periodically based on legal, technical, and business
              requirements.
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>
              12. Links to Third-Party Sites or Applications
            </h5>
            <h5 className={styles.par_text}>
              This Policy applies only to GetFlirt.AI. We are not responsible
              for the privacy practices or content of any third-party websites
              or apps. Please review their respective policies.
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>13. International Transfers</h5>
            <h5 className={styles.par_text}>
              Our operations and service providers may be located outside your
              country, which may mean that your Personal Information is
              transferred internationally and subject to foreign laws.
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>14. Changes to Our Policy</h5>
            <h5 className={styles.par_text}>
              We may update this Policy periodically. Continued use
            </h5>
          </p>

          <p className={styles.privacy_paragraph}>
            <h5 className={styles.par_title}>
              15. Consumer Health Data Privacy Policy
            </h5>
            <h5 className={styles.par_text}>
              GetFlirt.AI may collect Consumer Health Data as defined by
              applicable law. For details, please review our Consumer Health
              Data Privacy Policy.We may collect specific types of Consumer
              Health Data, such as information related to physical or mental
              health conditions, treatment information, use of prescribed
              medication, and certain health-related preferences or behaviors.
              This data is collected from you directly or inferred from
              interactions on our Service, including character chats, profile
              customization, or any health-related content you share.
            </h5>
          </p>
        </div>
      </AsideStructure>
    </div>
  );
};

export default PrivacyPolicy;
