import { clsx } from "clsx";
import styles from "./SuggestedPromptTab.module.css";

type Props = React.PropsWithChildren<{
  isActive?: boolean;
  onClick?: () => void;
}>;

const SuggestedPromptTab = ({ children, onClick, isActive }: Props) => {
  return (
    <li className={clsx(styles.item, { [styles.active]: isActive })} data-active={isActive} onClick={onClick}>
      {children}
    </li>
  );
};

export default SuggestedPromptTab;
