import { useState } from "react";
import styles from "./styles.module.css";
import { ButtonFactory } from "@shared/ui/Button";
import { PlanItem } from "@features/plan";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { IUserState, useUserStore } from "@entities/user";
import * as amplitude from "@amplitude/analytics-browser";

const DURATION_12 = "12 months";
const DURATION_3 = "3 months";
const DURATION_1 = "1 month";

const BONUSES = [
  {
    id: 1,
    duration: DURATION_12,
    price: "5.99",
    percentageOff: 75,
    was: "$25.99/month",
  },
  {
    id: 2,
    duration: DURATION_3,
    price: "9.99",
    percentageOff: 60,
    was: "$25.99/month",
  },
  {
    id: 3,
    duration: DURATION_1,
    price: "12.99",
    percentageOff: 60,
    was: "$25.99/month",
  },
];

const Bonuses = () => {
  const navigate = useNavigate();
  const [activeBonus, setActiveBonus] = useState<number>(1);
  const [isPaymentForOneMonth, setPaymentForOneMonth] = useState<boolean>(true);
  document.body.className = "";

  const user = useUserStore((state: IUserState) => state.user);

  const payWithCredit = () => {
    const active: any = BONUSES.find((item) => item.id === activeBonus);
    amplitude.track(`Premium Page Button Clicked`, {
      buttonType: "Pay with Credit Card",
    });

    navigate(
      `${ROUTES.PAYMENT}?price=${priceCalculate(active.duration)}&duration=${
        active.duration
      }&payment_type=subscribe`
    );
  };

  const payWithCrypto = () => {
    console.log(
      "payWithCrypto bonus id ->",
      BONUSES.find((item) => item.id === activeBonus)
    );
  };

  const setBonus = (id: number, duration: string) => {
    const oneMonthId = 3;
    id === oneMonthId
      ? setPaymentForOneMonth(false)
      : setPaymentForOneMonth(true);

    setActiveBonus(id);

    amplitude.track(`Subscription Plan Selected`, {
      plan_type: duration,
    });
  };

  const priceCalculate = (duration: string) => {
    if (duration === DURATION_12) {
      return "71.88";
    }
    if (duration === DURATION_3) {
      return "29.97";
    }
    if (duration === DURATION_1) {
      return "12.99";
    }
  };

  return (
    <div className={styles.plan_bonuses}>
      {BONUSES.map((planItem) => (
        <PlanItem
          key={planItem.id}
          plan={planItem}
          isActive={planItem.id === activeBonus}
          setActiveBonus={(id: number, duration: string) =>
            setBonus(id, duration)
          }
        />
      ))}
      <h6 className={styles.plan_no_adult}>
        No adult transaction in your bank statement
      </h6>
      <div className={styles.plan_actions}>
        <ButtonFactory
          variant="danger"
          label="Pay with Credit / Debit Card"
          type="button"
          onClick={payWithCredit}
        />

        {/* {isPaymentForOneMonth && (
          <ButtonFactory
            variant="primary"
            label="Pay with crypto"
            type="button"
            onClick={payWithCrypto}
          />
        )} */}
      </div>
      <div className={styles.plan_bottom_text}>
        {activeBonus === 1 && (
          <h6 className={styles.plan_details}>
            Annual Payment Billed As <span className="ls">$71.88</span>
          </h6>
        )}
        {activeBonus === 2 && (
          <h6 className={styles.plan_details}>
            Quarterly payment billed as <span className="ls">$29.97</span>
          </h6>
        )}
        {activeBonus === 3 && (
          <h6 className={styles.plan_details}>
            Monthly payment billed as <span className="ls">$12.99</span>
          </h6>
        )}
        <h6 className={styles.plan_details}>Cancel Anytime</h6>
      </div>
    </div>
  );
};

export default Bonuses;
