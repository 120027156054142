import { Circle } from "./Circle";

const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0;
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

type Props = {
  percentage: number;
  color?: string;
  secondaryColor?: string;
  width?: number;
  height?: number;
};

export function CircleProgress({
  percentage,
  color = '#FF3A2E',
  secondaryColor = 'rgba(255, 255, 255, 0.1)',
  width = 90,
  height = 90,
}: Props) {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={width} height={height} viewBox="0 0 200 200">
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle color="lightgrey" />
        <Circle color={secondaryColor} percentage={100} />
        <Circle color={color} percentage={pct} />
      </g>
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={"2.5em"}
        fill="#FFFFFF"
        strokeWidth={2.5}
        stroke="#FFFFFF"
      >
        {pct.toFixed(0)}%
      </text>
    </svg>
  );
}
