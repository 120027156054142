import { IAsideNavItem } from "@widgets/aside";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

interface IProps {
  item: IAsideNavItem;
  isActive: boolean;
  className?: string;
  isDesktop?: boolean;
}

const AsideNavItem: React.FC<IProps> = ({
  isActive,
  item,
  className,
  isDesktop,
}: IProps) => {
  return (
    <Link
      to={item.path}
      className={`${className} ${styles.aside_link_wrapper} ${
        isActive && styles.aside_link_active
      } ${item?.isDivider && styles.aside_divider}`}
    >
      <div className={styles.aside_link_img_container}>
        <img
          src={isActive && isDesktop ? item.activeIcon : item.icon}
          alt={`aside_${item.title}`}
        />
      </div>
      <span>{item.title}</span>
    </Link>
  );
};

export default AsideNavItem;
