import { clsx } from "clsx";
import { Button } from "@shared/ui/Button";
import { ReactComponent as ArrowCircle } from "@shared/assets/arrow_circle.svg";
import styles from "./Character.module.css";

type Props = {
  avatar?: string;
  name: string;
  onCharacterChange: () => void;
  className?: string;
};

const Character = ({ avatar, name, className, onCharacterChange }: Props) => {
  return (
    <div className={clsx(styles.character, className)}>
      <div className={styles.character__info}>
        <div className={styles.character__avatar}>
          <img src={avatar} alt="" />
        </div>
        <div>
          <h5 className={styles.character__name}>{name}</h5>
        </div>
      </div>

      <Button variant="outlined" size="sm" onClick={onCharacterChange}>
        <div className={styles.regenerate__button}>
          <ArrowCircle className={styles.character__regenerate_btn_icon} />
          Change
        </div>
      </Button>
    </div>
  );
};

export default Character;
