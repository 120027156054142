import { LinkFactory } from "@shared/ui/Link";
import styles from "./styles.module.css";
import { IModel, ROUTES } from "@shared/interfaces";
import { IUserState, useUserStore } from "@entities/user";
import { useState } from "react";
import ModelCardSkeleton from "./ModelCardSkeleton";
import { useSearchParams } from "react-router-dom";

interface IProps {
  model: any;
}

const ModelCard: React.FC<IProps> = ({ model }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useUserStore((state: IUserState) => state.user);
  const [isImgLoded, setImgLoded] = useState<boolean>(true);

  const onLoad = () => {
    setImgLoded(false);
  };

  const classes = [styles.model_wrapper];

  if (model.is_blured) {
    classes.push(styles.message_blur);
  }

  const generatePath = () => {
    searchParams.set("preview", model.id);
    setSearchParams(searchParams);
  };

  return (
    <div className={`${styles.model_container} ${isImgLoded ? styles.img_loded : ""}`}>
      <div className={styles.model_card_skeleton}>
        <ModelCardSkeleton />
      </div>
      <div onClick={generatePath} className={styles.link_typeof_div}>
        <div className={classes.join(" ")}>
          <div>
            <LinkFactory className={[styles.link_plans]} label="Remove blur" type="danger" path={`${ROUTES.PLANS}`} />
            <img src={model.image_url} alt={`model-${model.id}`} onLoad={onLoad} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelCard;
