import { useState } from "react";
import clsx from "clsx";
import type { IModel } from "@shared/interfaces";
import { Dialog } from "@shared/ui/Dialog";
import { Button } from "@shared/ui/Button";
import { useModelStore } from "@entities/model/model/model.store";
import styles from "./CharactersGalleryDialog.module.css";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelected: (model: IModel) => void;
};

const CharactersGalleryDialog = ({ isOpen, onClose, onSelected }: Props) => {
  const [selectedModel, setSelectedModel] = useState<IModel | null>(null);
  const { models } = useModelStore();

  const handleSelect = () => {
    if (selectedModel) {
      onClose();
      onSelected(selectedModel);
    }
  };

  const handleCancel = () => {
    onClose();
    setSelectedModel(null);
  };

  return (
    <Dialog
      isOpen={isOpen}
      isContentScrollable
      title="Choose Character"
      onClose={onClose}
      actions={
        <div className={styles.dialog__actions}>
          <Button onClick={handleCancel} fullWidth>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSelect} fullWidth disabled={!selectedModel}>
            Select
          </Button>
        </div>
      }
    >
      <div className={styles.character__gallery}>
        {models.map((model) => (
          <div
            className={clsx(styles.character__gallery__item, {
              [styles["character__gallery__item--selected"]]: model.id === selectedModel?.id,
            })}
            key={model.id}
            onClick={() => setSelectedModel(model)}
          >
            <img src={model.photo} alt={model.name} />
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default CharactersGalleryDialog;
