import { ModelCardFullMeta } from "./ui/ModelCardFullMeta";
import { ModelCard } from "./ui/ModelCard";
import { IModel } from "@shared/interfaces";

interface IProps {
  variant?: "full_meta";
  model: IModel;
  onClick?: () => void;
}

const GalleryItemFactory = ({ variant, model, onClick, ...props }: IProps) => {
  switch (variant) {
    case "full_meta":
      return <ModelCardFullMeta model={model} onClick={() => onClick && onClick()} {...props} />;

    default:
      return <ModelCard model={model} {...props} />;
  }
};

export default GalleryItemFactory;
