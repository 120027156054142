import { CreatedAiDialog, MyAiWidget } from "@widgets/my_ai";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { Portal } from "@widgets/portal";
import { ROUTES } from "@shared/interfaces";

const MyAiPage = () => {
  let { id } = useParams();
  const [isDialogAiShow, setDialogAiShow] = useState(false);
  const navigate = useNavigate();

  const { fetchModel } = useModelStore((state: IModelState) => state);
  const { model }: any = useModelStore((state: IModelState) => state.modelActive);

  useEffect(() => {
    if (!id) {
      setDialogAiShow(false);
      return;
    }

    fetchModel(id);
  }, [id]);

  useEffect(() => {
    if (!model?.id || !id) {
      return;
    }

    setDialogAiShow(true);
  }, [model.id]);

  const onClose = () => {
    setDialogAiShow(false);
    localStorage.removeItem("createdAi");

    navigate(`${ROUTES.MY_AI}`);
  };

  return (
    <div className={styles.my_ai_content}>
      <AsideStructure>
        <>
          {isDialogAiShow && (
            <Portal className="portal_home">
              <CreatedAiDialog model={model} onClose={onClose} />
            </Portal>
          )}
          <MyAiWidget />
        </>
      </AsideStructure>
    </div>
  );
};

export default MyAiPage;
