import Skeleton from "react-loading-skeleton";
import styles from "./styles-skeleton.module.css";

const ModelMessageSkeleton = () => {
  return (
    <div className={styles.model_skeleton}>
      <div className={styles.model_img}></div>

      <div className={styles.model_desc}>
        <Skeleton
          count={1}
          style={{ marginBottom: "6px", borderRadius: "16px", height: "27px" }}
        />
      </div>
    </div>
  );
};

export default ModelMessageSkeleton;
