import clsx from "clsx";
import Chip from "../Chip/Chip";
import styles from "./SuggestedPromptChips.module.css";

type Props = {
  className?: string;
  items: {
    label: string;
    prompt: string;
  }[];
  onClick: (prompt: string) => void;
};
const SuggestedPromptChips = ({ items, className, onClick }: Props) => {
  return (
    <div className={clsx(styles.chips, className)}>
      {items.map((item) => (
        <Chip key={item.label} onClick={() => onClick(item.prompt)}>
          {item.label}
        </Chip>
      ))}
    </div>
  );
};

export default SuggestedPromptChips;
